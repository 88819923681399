<template>
	<div id="homepage" class="pageContainer">
		<BannerFullsize />
	
		<ArticleParallax>
			<div class="pageContentText col-12 col-md-5 order-2 order-md-2">
				<h1 v-html="home.title"></h1>
				<p v-html="home.subtitle"></p>

				<!-- a href="" class="basicLink"><arrowRightIcon />{{ $t("readlink") }}</!-->
			</div>
		</ArticleParallax>

		<!-- <ProductsList>
			{{ home.featured_text1 }}
			<span>{{ home.featured_text2 }}</span>
		</ProductsList> -->

		<ProductsListHome>
			{{ home.featured_text1 }}
			<span>{{ home.featured_text2 }}</span>
		</ProductsListHome>

		<!-- <NewsSlider>
			<h1 class="pageTitleh2 h2">{{ $t("home-title-page") }}</h1>
		</NewsSlider>  -->
	</div>
</template>

<script>
	import BannerFullsize from "../components/BannerFullsize";
	import ArticleParallax from "@/components/ArticleParallax";
	import arrowRightIcon from "@/components/ui/arrow-slim-right.vue";
	import ProductsListHome from "@/components/ProductsListHome";
	import NewsSlider from "@/components/NewsSlider";

	export default {
		name: "homepage",
		components: {
			BannerFullsize,
			ArticleParallax,
			arrowRightIcon,
			ProductsListHome,
			NewsSlider
		},
		data() {
			return {
				// newsAmount: 3,
				// hasPaging: false,
				// hasLink: true,
				home: "",
				gallery1: [],
				gallery2: [],
				slidergallery: [],
				hasFeaturedProducts: true,
				productsPerPage: 6,
				bannerLoaded: false,
				hasJsonData: false,
				productsAmount: 6,
      			hasPaging: true
			};
		},
		methods: {
			notifyFinishLoad() {
				if (!(this.bannerLoaded && this.hasJsonData)) return;
				this.$eventBus.$emit("pageFinishLoad", true);
			}
		},
		mounted() {
			this.$eventBus.$on("componentFinishLoad", data => {
				if (data == "bannerLoaded") {
					this.bannerLoaded = true;
					this.notifyFinishLoad();
					return;
				}
			});

			this.$http
				.get(
					"https://www.bstone.pt/webservices/" + this.$i18n.locale + "/homepage2"
				)
				.then(response => {
					this.home = response.data;
					this.gallery1 = response.data.gallery1;
					this.gallery2 = response.data.gallery2;
					this.slidergallery = response.data.slidergallery;

					this.hasJsonData = true;
					this.notifyFinishLoad();
				});
		}
	};
</script>
<style lang="scss">
	
	// #homepage .parallaxGroup1 .imageParallax1 {
	// 	top: 20%;
	// }
	#homepage .parallaxGroup1 {
		margin-top: -250px;
	}
	#homepage .parallaxGroup2{
		background-color: transparent;
		// margin-top: 100px;
	}
	#homepage .pageContentText{
		margin-top: auto;
		padding-top: 250px;
	}
	#homepage .feautedproductList  {
		background: url(/img/B_Simbolo_estatico_Corpo.9f583e65.svg) no-repeat 75% 100% fixed;
		background-size: 250px;
		padding-top: 0;
    // padding-right: 14%;
	}
	@media (min-width: 2200px) {
		#homepage .feautedproductList {
			background: url(/img/B_Simbolo_estatico_Corpo.9f583e65.svg) no-repeat 65% 100% fixed;
			-webkit-background-size:  250px;
			-moz-background-size:     250px;
			-o-background-size:       250px;
			background-size:          250px;
			padding-top: 0 !important;
			// padding-right: 14%;
		}
	}
	

	.basicLink {
		font-family: "Oswald", sans-serif;
		font-size: 15px;
		font-weight: 300;
		letter-spacing: 2px;
		text-decoration: none;
		text-transform: uppercase;
		color: #000;
		position: relative;
		margin-top: 5%;

		&::after {
			position: absolute;
			top: 11px;
			right: -60px;
			content: "";
			display: block;
			height: 1px;
			width: 40px;
			background-color: #000;
		}

		.arrowSlimIcon {
			position: absolute;
			top: -3px;
			right: -60px;

			svg line,
			svg path {
				stroke: #000;
			}
		}

		&:hover {
			color: #000;

			&::after {
				background-color: #b76646;
			}
			svg line,
			svg path {
				stroke: #b76646;
			}
		}
	}
	@media (max-width: 1600px) {
		#homepage .parallaxGroup2{
		background-color: transparent;
		margin-top: 100px;
		}
	}
	@media (max-width: 1200px) {
		#homepage .parallaxGroup1 .imageParallax1{
			top: 20%;
		}
		#homepage .parallaxGroup1 .imageParallax2{
			top: 40%;
		}
		
	}
	@media (max-width: 812px){
		#homepage .parallaxGroup1 {
			margin-top: 0px;
		}
		.titlehomefeatured {
			padding-top: 150px;
		}
		#homepage .pageContentText {
			flex: 100%;
			max-width: 100%;
		}
		#homepage .order-sm-2 {
			order: 3 !important; 
		}
		#homepage .pageContentText{
			margin-top: 0px ;
			padding: 100px ;
			padding-bottom: 00px ;
		}
		#homepage .parallaxGroup1 .imageParallax1,
		#homepage .parallaxGroup1 .imageParallax2,
		#homepage .parallaxGroup2 img{
			transform: none !important;
		}
		#homepage .parallaxGroup1 img, #homepage .parallaxGroup2 img {
			position: initial;
		}
		#homepage .parallaxGroup2 img {
			padding-bottom: 20px;
		}
		#homepage .parallaxGroup2  {
			height: auto;
		}
		#homepage .parallaxGroup1 .col-sm-6.imageParallax1 {
			flex: 0 0 50%;
			max-width: 100% ;
		}
		#homepage .parallaxGroup1 img.imageParallax2{
			float: none;
		}
		#homepage .parallaxGroup1 .rellaxArticle.col-9 {
			max-width: 100%;
		}
		#homepage .rellaxArticle.col-md-6, #homepage .rellaxArticle.col-md-5, #homepage .parallaxGroup2 .rellaxArticle.col-9 { 
			max-width: 66%;
		}
		#homepage .h2.pageTitleh2{
			padding-top: 60px;
		}
		#homepage .wrapper .masonry{
			display: block;
		}
		#homepage .wrapper .masonry[page="2"] {
			max-height: initial;
		}
		#homepage .wrapper a {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto 0;
		}
		#homepage .parallaxContainer {
			padding-right: 0;
		}
		#homepage .parallaxGroup2 {
			text-align: center;
		}
	}
	@media (max-width: 767px) {
		
		
		
		
		


		#homepage .parallaxGroup2 img{

		}
	}
</style>
