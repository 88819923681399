<template>
	<keep-alive>
		<!-- <div class="dropdown menu">
			<div >
				<ul class="dropdown-toggle" @click="showMenu = !showMenu">
					<li  v-for="item in itensMenu" :key="item.id" itemprop="name">
						<router-link :to="item.link" itemprop="url">{{item.name}}</router-link>

						<ul :class="{ show: showMenu }">
							<li v-for="t in item.submenu">
								{{ t.name }}
							</li>
						</ul>
					</li>
				</ul>
				
				
				
			</div>
		</div> -->


		<nav class="menu">
            <ul itemscope itemtype="http://www.schema.org/SiteNavigationElement">
                <li v-for="item in itensMenu" :key="item.id" itemprop="name">
					<router-link  v-if="!item.submenu" :to="item.link" itemprop="url">{{item.name}}</router-link>
                    					
                    <span 
                        v-else
              					
						>
                        {{ item.name }}
                        
                        <ul class="dropdown"
                           :class="{ isOpen }">
                            <li
                                v-for="child in item.submenu">
                                <a :href="child.link">
                                    {{ child.name }}
                                </a>
                            </li>
                        </ul>
                    </span>
                </li>
            </ul>
        </nav>
		<!-- <nav class="menu">
			<ul itemscope itemtype="http://www.schema.org/SiteNavigationElement">
				<li v-for="item in itensMenu" :key="item.id" itemprop="name">
					<router-link :to="item.link" itemprop="url">{{item.name}}</router-link>

					<ul>
						<li v-for="t in item.submenu">
							{{ t.name }}
						</li>
					</ul>
				</li>
				
				
			</ul>
		</nav> -->

	</keep-alive>
</template>

<script>
	export default {
		data() {
			return {
				itensMenu: [],
				isOpen: false,
			};
			
		},
		
		created() {
			this.$eventBus.$on("jsonGlobalLoaded", response => {
				this.itensMenu = response.data.menu;
				
				this.itemSubMenu = response.data.menu.submenu;
				
			});
		},
		beforeDestroy() {
			this.$eventBus.$off("jsonGlobalLoaded"); // releases the subscription
		}
	};

	
</script>
<style>
span:hover .dropdown{
  display: block;
}

</style>