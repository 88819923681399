import Vuex from 'vuex'

export const store = new Vuex.Store({
    state: {
        cart: [],
        token: null
    },
    mutations:
    {
        addToCart: (state, productId) => {
            let cartProduct = state.cart.find((product) => product == productId) // find the product in the cart list
            if (cartProduct) return // Already in the cart
            state.cart.push(productId)
            $cookies.set("cartArray", state.cart.join(';'));
        },
        removeFromCart: (state, productId) => {
            let cartProductIndex = state.cart.findIndex((product) => product == productId); // find the product in the cart list
            if (cartProductIndex < 0) return // not in the cart
            state.cart.splice(cartProductIndex, 1)
            $cookies.set("cartArray", state.cart.join(';'));
        },
        emptyCart: (state) => {
            state.cart = [];
            $cookies.set("cartArray", '');
        },
        login: (state, token) => {
            state.token = token
            $cookies.set("userToken", state.token);
        },
        logout: (state, token) => {
            state.token = null
            $cookies.remove("userToken");
        }
    },
    actions:
    {
        addToCart: ({ commit }, productId) => {
            commit("addToCart", productId)
        },
        removeFromCart: ({ commit }, productId) => {
            commit("removeFromCart", productId)
        },
        checkItemInCart: ({ commit }, productId) => {
            commit("removeFromCart", productId)
        },
        emptyCart: ({ commit }) => {
            commit("emptyCart")
        },
        login: ({ commit }, token) => {
            commit("login", token)
        },
        logout: ({ commit }, token) => {
            commit("logout", token)
        }
    },
    getters:
    {
        getCart: (state) => {
            if (!$cookies.isKey("cartArray")) return []
            let item = $cookies.get("cartArray")
            if (item) state.cart = item.split(';')
            return state.cart
        },
        cartSize: (state) => {
            return state.cart.length
        },
        isItemInCart: state => productId => {
            return state.cart.find((product) => product == productId) != null
        },
        isLoggedIn: (state) => {
            if (state.token == null && !$cookies.isKey("userToken")) return false
            state.token = $cookies.get("userToken")
            return state.token != null
        },
        getToken: (state) => {
            if (!$cookies.isKey("userToken")) return null
            state.token = $cookies.get("userToken")
            return state.token
        }
    }
})