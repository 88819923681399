<template>
	<div id="bannerAnimation">
		<svg id="animatedlogo" v-if="name=='Home'" alt="Preloading B Stone" v-once>
			<g id="Rreferencias">
				<g transform="translate(8297.094 4942.638)">
					<path
						class="st0"
						d="M-8251.7-4915.6c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.4-0.8-0.7v0.9h-1.4v-15.7h1.4v5.4
              c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.4,1.3-0.3c0.5,0,0.9,0.1,1.3,0.4c0.3,0.3,0.6,0.6,0.8,1c0.2,0.5,0.3,0.9,0.4,1.4
              c0.1,0.5,0.1,1.1,0.1,1.6v2.4c0,0.8-0.1,1.7-0.2,2.5c-0.1,0.6-0.4,1.2-0.8,1.6C-8250.5-4915.8-8251.1-4915.6-8251.7-4915.6z
                M-8252-4916.7c0.4,0,0.7-0.1,1-0.4c0.2-0.4,0.4-0.8,0.4-1.2c0.1-0.6,0.1-1.3,0.1-2v-2.4c0-0.6,0-1.2-0.1-1.8
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.3,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.6v7.6c0.2,0.2,0.5,0.4,0.8,0.6
              C-8252.7-4916.8-8252.3-4916.7-8252-4916.7z"
					/>
					<path class="st0" d="M-8245-4915.8v-15.7h1.4v15.7H-8245z" />
					<path
						class="st0"
						d="M-8236.5-4915.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8235.2-4915.7-8235.9-4915.6-8236.5-4915.6z
                M-8236.5-4916.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8237.3-4916.8-8236.9-4916.6-8236.5-4916.7z"
					/>
					<path
						class="st0"
						d="M-8226.8-4915.6c-1.4,0.2-2.6-0.8-2.8-2.1c0,0,0,0,0,0c-0.1-0.7-0.2-1.5-0.2-2.3v-2.6c0-0.9,0.1-1.7,0.3-2.6
              c0.1-0.6,0.4-1.1,0.9-1.5c1.1-0.6,2.5-0.6,3.7,0c0.4,0.3,0.7,0.8,0.8,1.3c0.2,0.6,0.2,1.2,0.2,1.9v0.5h-1.3v-0.5
              c0-0.5,0-1-0.1-1.5c-0.1-0.3-0.2-0.6-0.5-0.8c-0.3-0.2-0.6-0.2-0.9-0.2c-0.4,0-0.7,0.1-1,0.3c-0.3,0.3-0.4,0.6-0.5,1
              c-0.1,0.6-0.1,1.3-0.1,1.9v3.1c-0.1,0.8,0.1,1.6,0.3,2.4c0.2,0.5,0.8,0.7,1.3,0.7c0.4,0,0.7-0.1,1-0.3c0.2-0.2,0.4-0.6,0.4-0.9
              c0.1-0.5,0.1-1,0.1-1.5v-0.6h1.3v0.5c0,0.7-0.1,1.3-0.2,2c-0.1,0.5-0.4,1-0.8,1.4C-8225.5-4915.7-8226.2-4915.6-8226.8-4915.6z"
					/>
					<path
						class="st0"
						d="M-8217.3-4915.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c1.1-0.7,2.5-0.7,3.6,0c0.5,0.4,0.8,0.9,0.9,1.4c0.2,0.8,0.3,1.5,0.3,2.3v3.1
              c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8216.1-4915.7-8216.7-4915.6-8217.3-4915.6z M-8217.3-4916.7
              c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6c0-0.4-0.2-0.8-0.4-1.1
              c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3c0,0.5,0,1.1,0.1,1.6
              c0,0.4,0.2,0.8,0.4,1.1C-8218.1-4916.8-8217.7-4916.6-8217.3-4916.7z"
					/>
				</g>
				<g transform="translate(8279.902 4965.921)">
					<path
						class="st0"
						d="M-8252.1-4915.6c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.8-0.8-1-1.4c-0.2-0.8-0.3-1.7-0.3-2.6v-2.8
              c0-0.9,0.1-1.8,0.3-2.6c0.2-0.6,0.5-1,1-1.3c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.3,0.1,1.8,0.5c0.4,0.4,0.8,0.9,0.9,1.5
              c0.2,0.9,0.3,1.7,0.2,2.6v1h-4.5v2c0,0.6,0,1.2,0.2,1.7c0.1,0.4,0.3,0.7,0.5,0.9c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.5-0.1,0.8-0.2
              c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.4,0.2-0.9,0.2-1.3v-0.8h1.4v0.6c0,0.9-0.2,1.7-0.6,2.5
              C-8250.4-4915.9-8251.3-4915.5-8252.1-4915.6z M-8253.7-4922.3h3.1v-0.9c0-0.5,0-1-0.1-1.5c0-0.4-0.2-0.7-0.4-1
              c-0.3-0.2-0.6-0.4-1-0.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c-0.1,0.6-0.2,1.2-0.2,1.9L-8253.7-4922.3z"
					/>
					<path
						class="st0"
						d="M-8246.2-4915.8l2.7-5.9l-2.5-5.4h1.4l1.9,4.1l1.8-4.1h1.4l-2.6,5.5l2.7,5.7h-1.4l-2.1-4.4l-2,4.4
              L-8246.2-4915.8z"
					/>
					<path
						class="st0"
						d="M-8236.1-4912.1v-14.9h1.4v0.9c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.4,1.3-0.3c0.5,0,0.9,0.1,1.3,0.4
              c0.3,0.3,0.6,0.6,0.8,1c0.2,0.5,0.3,0.9,0.4,1.4c0.1,0.5,0.1,1.1,0.1,1.6v2.4c0,0.8-0.1,1.7-0.2,2.5c-0.1,0.6-0.4,1.2-0.8,1.6
              c-0.4,0.4-1,0.6-1.6,0.6c-0.4,0-0.8-0.1-1.2-0.4c-0.3-0.2-0.6-0.4-0.8-0.7v4.6L-8236.1-4912.1z M-8232.9-4916.7
              c0.4,0,0.7-0.1,1-0.4c0.2-0.4,0.4-0.8,0.4-1.2c0.1-0.6,0.1-1.3,0.1-2v-2.4c0-0.6,0-1.2-0.1-1.8c0-0.4-0.2-0.8-0.4-1.1
              c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.6v7.6c0.2,0.2,0.5,0.4,0.8,0.6
              C-8233.6-4916.8-8233.2-4916.7-8232.9-4916.7L-8232.9-4916.7z"
					/>
					<path class="st0" d="M-8225.9-4915.8v-15.7h1.4v15.7H-8225.9z" />
					<path
						class="st0"
						d="M-8217.4-4915.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8216.1-4915.7-8216.8-4915.6-8217.4-4915.6z
                M-8217.4-4916.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8218.2-4916.8-8217.8-4916.6-8217.4-4916.7L-8217.4-4916.7z"
					/>
					<path
						class="st0"
						d="M-8210.4-4915.8v-11.2h1.4v1.5c0.3-0.5,0.7-1,1.2-1.3c0.4-0.2,0.9-0.4,1.3-0.4h0.1c0,0,0.1,0,0.1,0v1.5
              c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.7,0.6-0.9,1v8.6L-8210.4-4915.8z"
					/>
					<path
						class="st0"
						d="M-8200.1-4915.6c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.8-0.8-1-1.4c-0.2-0.8-0.3-1.7-0.3-2.6v-2.8
              c0-0.9,0.1-1.8,0.3-2.6c0.2-0.6,0.5-1,1-1.3c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.3,0.1,1.8,0.5c0.4,0.4,0.8,0.9,0.9,1.5
              c0.2,0.9,0.3,1.7,0.2,2.6v1h-4.5v2c0,0.6,0,1.2,0.2,1.7c0.1,0.4,0.3,0.7,0.5,0.9c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.5-0.1,0.8-0.2
              c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.4,0.2-0.9,0.2-1.3v-0.8h1.4v0.6c0,0.9-0.2,1.7-0.6,2.5
              C-8198.3-4915.9-8199.2-4915.5-8200.1-4915.6z M-8201.7-4922.3h3.1v-0.9c0-0.5,0-1-0.1-1.5c0-0.4-0.2-0.7-0.4-1
              c-0.3-0.2-0.6-0.4-1-0.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c-0.1,0.6-0.2,1.2-0.2,1.9L-8201.7-4922.3z"
					/>
				</g>
				<g transform="translate(8281.883 4990.233)">
					<path
						class="st0"
						d="M-8255.1-4913.1v-14.9h1.4v0.9c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.4,1.3-0.3c0.5,0,0.9,0.1,1.3,0.4
              c0.3,0.3,0.6,0.6,0.8,1c0.2,0.5,0.3,0.9,0.4,1.4c0.1,0.5,0.1,1.1,0.1,1.6v2.4c0,0.8-0.1,1.7-0.2,2.5c-0.1,0.6-0.4,1.2-0.8,1.6
              c-0.4,0.4-1,0.6-1.6,0.6c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.4-0.8-0.7v4.6L-8255.1-4913.1z M-8251.9-4917.8
              c0.4,0,0.7-0.1,1-0.4c0.2-0.4,0.4-0.8,0.4-1.2c0.1-0.6,0.1-1.3,0.1-2v-2.4c0-0.6,0-1.2-0.1-1.8c0-0.4-0.2-0.8-0.4-1.1
              c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.6v7.6c0.2,0.2,0.5,0.4,0.8,0.6
              C-8252.6-4917.8-8252.3-4917.7-8251.9-4917.8L-8251.9-4917.8z"
					/>
					<path
						class="st0"
						d="M-8245-4916.8v-11.2h1.4v1.5c0.3-0.5,0.7-1,1.2-1.3c0.4-0.2,0.9-0.4,1.3-0.4h0.1c0,0,0.1,0,0.1,0v1.5
              c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.7,0.6-0.9,1v8.6L-8245-4916.8z"
					/>
					<path
						class="st0"
						d="M-8234.7-4916.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8233.4-4916.8-8234-4916.6-8234.7-4916.6z
                M-8234.7-4917.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8235.5-4917.8-8235.1-4917.7-8234.7-4917.7L-8234.7-4917.7z"
					/>
					<path
						class="st0"
						d="M-8228.4-4913.9c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.4-0.1v-1.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0
              c0.3,0,0.6-0.1,0.9-0.2c0.2-0.1,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.5,0.1-0.7v-11.5h1.4v11.6c0.1,0.7-0.2,1.4-0.7,2
              C-8227.1-4914-8227.8-4913.8-8228.4-4913.9z M-8227.4-4929.6v-1.9h1.4v1.9L-8227.4-4929.6z"
					/>
					<path
						class="st0"
						d="M-8218.9-4916.6c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.8-0.8-1-1.4c-0.2-0.8-0.3-1.7-0.3-2.6v-2.8
              c0-0.9,0.1-1.8,0.3-2.6c0.2-0.6,0.5-1,1-1.3c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.3,0.1,1.8,0.5c0.4,0.4,0.8,0.9,0.9,1.5
              c0.2,0.9,0.3,1.7,0.2,2.6v1h-4.5v2c0,0.6,0,1.2,0.2,1.7c0.1,0.4,0.3,0.7,0.5,0.9c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.5-0.1,0.8-0.2
              c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.4,0.2-0.9,0.2-1.3v-0.8h1.4v0.6c0,0.9-0.2,1.7-0.6,2.5C-8217.2-4916.9-8218-4916.5-8218.9-4916.6z
                M-8220.5-4923.4h3.1v-0.9c0-0.5,0-1-0.1-1.5c0-0.4-0.2-0.7-0.4-1c-0.3-0.2-0.6-0.4-1-0.3c-0.3,0-0.7,0.1-0.9,0.3
              c-0.3,0.2-0.5,0.5-0.5,0.9c-0.1,0.6-0.2,1.2-0.2,1.9L-8220.5-4923.4z"
					/>
					<path
						class="st0"
						d="M-8209.3-4916.6c-1.4,0.2-2.6-0.8-2.8-2.1c0,0,0,0,0,0c-0.1-0.7-0.2-1.5-0.2-2.3v-2.6c0-0.9,0.1-1.7,0.3-2.6
              c0.1-0.6,0.4-1.1,0.9-1.5c1.1-0.6,2.5-0.6,3.7,0c0.4,0.3,0.7,0.8,0.8,1.3c0.2,0.6,0.2,1.2,0.2,1.9v0.5h-1.3v-0.5
              c0-0.5,0-1-0.1-1.5c-0.1-0.3-0.2-0.6-0.5-0.8c-0.3-0.2-0.6-0.2-0.9-0.2c-0.4,0-0.7,0.1-1,0.3c-0.3,0.3-0.4,0.6-0.5,1
              c-0.1,0.6-0.1,1.3-0.1,1.9v3.1c-0.1,0.8,0.1,1.6,0.3,2.4c0.2,0.5,0.8,0.7,1.3,0.7c0.4,0,0.7-0.1,1-0.3c0.2-0.2,0.4-0.6,0.4-0.9
              c0.1-0.5,0.1-1,0.1-1.5v-0.6h1.3v0.5c0,0.7-0.1,1.3-0.2,2c-0.1,0.5-0.4,1-0.8,1.4C-8208-4916.8-8208.7-4916.6-8209.3-4916.6z"
					/>
					<path
						class="st0"
						d="M-8200-4916.7c-0.4,0-0.9-0.1-1.3-0.3c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.4-0.2-0.8-0.2-1.3v-8h-1.4v-0.9h1.4
              v-3.5h1.4v3.5h1.9v0.9h-1.9v7.8c0,0.4,0,0.8,0.2,1.2c0.1,0.2,0.4,0.3,0.8,0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4-0.1v1.1
              c-0.2,0-0.4,0.1-0.6,0.1C-8199.6-4916.7-8199.8-4916.7-8200-4916.7z"
					/>
				</g>
				<g transform="translate(8255.145 5013.517)">
					<path
						class="st0"
						d="M-8255.1-4929.6v-1.9h1.4v1.9H-8255.1z M-8255.1-4916.8v-11.2h1.4v11.2H-8255.1z"
					/>
					<path
						class="st0"
						d="M-8249.4-4916.8v-11.2h1.4v1.2c0.4-0.4,0.8-0.7,1.2-1c0.5-0.3,1-0.4,1.5-0.4c0.3,0,0.7,0.1,1,0.3
              c0.3,0.2,0.5,0.5,0.6,0.8c0.1,0.4,0.2,0.9,0.2,1.3v9h-1.4v-8.7c0-0.4-0.1-0.9-0.3-1.2c-0.2-0.2-0.5-0.4-0.8-0.4
              c-0.4,0-0.7,0.1-1,0.3c-0.4,0.2-0.7,0.5-1,0.8v9.2L-8249.4-4916.8z"
					/>
					<path
						class="st0"
						d="M-8239.5-4916.8v-11.2h1.4v1.2c0.4-0.4,0.8-0.7,1.2-1c0.5-0.3,1-0.4,1.5-0.4c0.3,0,0.7,0.1,1,0.3
              c0.3,0.2,0.5,0.5,0.6,0.8c0.1,0.4,0.2,0.9,0.2,1.3v9h-1.4v-8.7c0-0.4-0.1-0.9-0.3-1.2c-0.2-0.2-0.5-0.4-0.8-0.4
              c-0.3,0-0.7,0.1-1,0.3c-0.4,0.2-0.7,0.5-1,0.8v9.2L-8239.5-4916.8z"
					/>
					<path
						class="st0"
						d="M-8226.9-4916.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8225.6-4916.8-8226.3-4916.6-8226.9-4916.6z
                M-8226.9-4917.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8227.7-4917.8-8227.3-4917.7-8226.9-4917.7z"
					/>
					<path class="st0" d="M-8218.3-4916.8l-2.4-11.2h1.4l1.8,9.6l1.8-9.6h1.4l-2.3,11.2H-8218.3z" />
					<path
						class="st0"
						d="M-8209-4916.6c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.5-0.8-0.9c-0.2-0.4-0.3-0.8-0.3-1.3
              c0-0.5,0.1-1,0.2-1.5c0.2-0.4,0.4-0.8,0.8-1.1c0.4-0.4,0.9-0.7,1.4-0.9c0.6-0.3,1.3-0.6,2.1-1v-0.8c0-0.5,0-1.1-0.1-1.6
              c0-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.4-0.2,0.8-0.2,1.2v0.4
              l-1.4,0c-0.1-0.9,0.2-1.8,0.7-2.5c0.6-0.6,1.5-0.9,2.3-0.8c0.8-0.1,1.6,0.3,2.1,0.9c0.5,0.9,0.7,1.8,0.6,2.8v5.5
              c0,0.2,0,0.4,0,0.7s0,0.6,0,0.9s0,0.5,0,0.6h-1.3c0-0.2-0.1-0.5-0.1-0.8s-0.1-0.5-0.1-0.7c-0.2,0.5-0.5,0.9-0.8,1.2
              C-8207.9-4916.8-8208.4-4916.6-8209-4916.6z M-8208.5-4917.8c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5
              c0.2-0.2,0.3-0.4,0.4-0.6v-3.5c-0.6,0.3-1,0.5-1.4,0.8c-0.3,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.4,0.5-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,1
              c0,0.5,0.1,0.9,0.4,1.3C-8209.2-4918-8208.9-4917.8-8208.5-4917.8L-8208.5-4917.8z"
					/>
					<path
						class="st0"
						d="M-8198.7-4916.7c-0.4,0-0.9-0.1-1.3-0.3c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.4-0.2-0.8-0.2-1.3v-8h-1.4v-0.9h1.4
              v-3.5h1.4v3.5h1.9v0.9h-1.9v7.8c0,0.4,0,0.8,0.2,1.2c0.1,0.2,0.4,0.3,0.8,0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4-0.1v1.1
              c-0.2,0-0.4,0.1-0.6,0.1C-8198.3-4916.7-8198.5-4916.7-8198.7-4916.7z"
					/>
					<path
						class="st0"
						d="M-8193.8-4929.6v-1.9h1.4v1.9H-8193.8z M-8193.8-4916.8v-11.2h1.4v11.2H-8193.8z"
					/>
					<path
						class="st0"
						d="M-8185.3-4916.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8184-4916.8-8184.7-4916.6-8185.3-4916.6z
                M-8185.3-4917.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8186.1-4917.8-8185.7-4917.7-8185.3-4917.7z"
					/>
					<path
						class="st0"
						d="M-8178.4-4916.8v-11.2h1.4v1.2c0.4-0.4,0.8-0.7,1.2-1c0.5-0.3,1-0.4,1.5-0.4c0.3,0,0.7,0.1,1,0.3
              c0.3,0.2,0.5,0.5,0.6,0.8c0.1,0.4,0.2,0.9,0.2,1.3v9h-1.4v-8.7c0-0.4-0.1-0.9-0.3-1.2c-0.2-0.2-0.5-0.4-0.8-0.4
              c-0.4,0-0.7,0.1-1,0.3c-0.4,0.2-0.7,0.5-1,0.8v9.2L-8178.4-4916.8z"
					/>
				</g>
			</g>
			<g id="Stone">
				<g transform="translate(8549.963 4957.899)">
					<path
						class="st0"
						d="M-8239.2-4917.1h13.2l2.4,4.9h-15.6v22.1c0,8.6,2,11,7.3,11c3.6,0,7-2,8.8-5.2l0.9,1.8
              c-4.3,7-9.1,10.6-16.2,10.6c-6.2,0-11.5-3.6-11.5-13.8v-26.4l-5.1-10.4l15.9-8.9L-8239.2-4917.1z"
					/>
				</g>
				<g transform="translate(8590.654 4971.258)">
					<path
						class="st0"
						d="M-8235.7-4885.3c-12.8,0-19.5-10.3-19.5-21.1c0-11.6,7.8-25.1,24.5-25.1c12.8,0,19.5,10.3,19.5,21.1
              C-8211.1-4898.8-8218.9-4885.3-8235.7-4885.3 M-8243.7-4914.6c0,14,5.1,25.9,13,25.9c6.6,0,8.4-8.8,8.4-13.5
              c0-14-5.2-25.9-13.1-25.9C-8242.9-4928.1-8243.7-4920-8243.7-4914.6"
					/>
				</g>
				<g transform="translate(8641.697 4969.75)">
					<path
						class="st0"
						d="M-8221.7-4884.9v-27.7c0-6.9-1-11.3-7.9-11.3c-3.3,0-6.7,1.4-9.6,5.3v33.7h-10.8v-27.3l-5.1-10.4l15.9-8.9
              v9.9c3.2-5.1,8.8-8.2,14.8-8.4c9.2,0,13.5,5.9,13.5,14.4v30.7L-8221.7-4884.9z"
					/>
				</g>
				<g transform="translate(8697.499 4971.183)">
					<path
						class="st0"
						d="M-8215.9-4911.8h-28.9c1,11.6,7.2,16.5,14.2,16.5c6.1,0,11.5-3.1,12.9-6.9l1.4,0.5
              c-3.2,8.2-9.6,16.5-20.4,16.5c-11.8,0-18.5-10.5-18.5-21.4c0-11.6,7.2-25,21.9-25c10.7,0,17.3,7.2,17.4,19.4L-8215.9-4911.8
              L-8215.9-4911.8L-8215.9-4911.8z M-8227.5-4915.4c0-9.6-3.9-13.4-7.8-13.4c-6.8,0-9.6,8.2-9.6,13.6v0.6h17.5V-4915.4z"
					/>
				</g>
				<g transform="translate(8507.032 4971.242)">
					<path
						class="st0"
						d="M-8225.2-4928.8c-3.9-1.7-8.1-2.6-12.4-2.7c-4.4,0-17.5,1-17.5,14.5s23.1,10.7,23.1,24c0,2.8-2.5,4.1-4.3,4.6
              c-0.9,0.3-1.8,0.4-2.7,0.4h-2.5l-13.7-18.1v18.1c0,0,8.5,2.7,15.5,2.7c3.6,0,18.1-1.4,18.1-15.2s-24.1-13-24.1-22.9
              c0-2.2,1.4-4.2,3.6-4.8c1-0.4,2.1-0.6,3.1-0.6h3l10.7,15.1L-8225.2-4928.8z"
					/>
				</g>
			</g>
			<g id="B">
				<g transform="translate(8398.24 4957.861)">
					<path
						class="st0"
						d="M-8224.8-4904.8c6.3-2.4,13.5-6.2,13.5-13.8c0-10.2-9.7-12.9-24.1-12.9h-3.3l-16.4,9.2l5.3,10.7l0,38.6h13.7
              c12.1,0,28.6-3.3,28.6-15.6C-8207.5-4897.3-8214.1-4902.4-8224.8-4904.8 M-8235.8-4928.8c5.9,0,13.5,1.6,13.5,11.9
              c0,6.7-5.5,11.6-16.4,11.6v-23.5H-8235.8z M-8236.1-4876.3h-2.6v-26.8c10.9,0,18.6,3,18.6,14.3
              C-8220.1-4880.7-8227.4-4876.3-8236.1-4876.3"
					/>
				</g>
				<g transform="translate(8367.255 4931.491)">
					<path
						class="st0"
						d="M-8255.1-4931.5v111.2h94.4l16.9-16.9v-94.4L-8255.1-4931.5z M-8247.5-4827.9v-96h96v83.6l0,0l-12.3,12.3l0,0
              H-8247.5z"
					/>
				</g>
			</g>
		</svg>
		
		<svg id="animatedlogo" v-else alt="Preloading B Stone" v-once>
			<g id="">
				<g transform="translate(8297.094 4942.638)">
					<path
						class="st0"
						d="M-8251.7-4915.6c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.4-0.8-0.7v0.9h-1.4v-15.7h1.4v5.4
              c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.4,1.3-0.3c0.5,0,0.9,0.1,1.3,0.4c0.3,0.3,0.6,0.6,0.8,1c0.2,0.5,0.3,0.9,0.4,1.4
              c0.1,0.5,0.1,1.1,0.1,1.6v2.4c0,0.8-0.1,1.7-0.2,2.5c-0.1,0.6-0.4,1.2-0.8,1.6C-8250.5-4915.8-8251.1-4915.6-8251.7-4915.6z
                M-8252-4916.7c0.4,0,0.7-0.1,1-0.4c0.2-0.4,0.4-0.8,0.4-1.2c0.1-0.6,0.1-1.3,0.1-2v-2.4c0-0.6,0-1.2-0.1-1.8
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.3,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.6v7.6c0.2,0.2,0.5,0.4,0.8,0.6
              C-8252.7-4916.8-8252.3-4916.7-8252-4916.7z"
					/>
					<path class="st0" d="M-8245-4915.8v-15.7h1.4v15.7H-8245z" />
					<path
						class="st0"
						d="M-8236.5-4915.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8235.2-4915.7-8235.9-4915.6-8236.5-4915.6z
                M-8236.5-4916.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8237.3-4916.8-8236.9-4916.6-8236.5-4916.7z"
					/>
					<path
						class="st0"
						d="M-8226.8-4915.6c-1.4,0.2-2.6-0.8-2.8-2.1c0,0,0,0,0,0c-0.1-0.7-0.2-1.5-0.2-2.3v-2.6c0-0.9,0.1-1.7,0.3-2.6
              c0.1-0.6,0.4-1.1,0.9-1.5c1.1-0.6,2.5-0.6,3.7,0c0.4,0.3,0.7,0.8,0.8,1.3c0.2,0.6,0.2,1.2,0.2,1.9v0.5h-1.3v-0.5
              c0-0.5,0-1-0.1-1.5c-0.1-0.3-0.2-0.6-0.5-0.8c-0.3-0.2-0.6-0.2-0.9-0.2c-0.4,0-0.7,0.1-1,0.3c-0.3,0.3-0.4,0.6-0.5,1
              c-0.1,0.6-0.1,1.3-0.1,1.9v3.1c-0.1,0.8,0.1,1.6,0.3,2.4c0.2,0.5,0.8,0.7,1.3,0.7c0.4,0,0.7-0.1,1-0.3c0.2-0.2,0.4-0.6,0.4-0.9
              c0.1-0.5,0.1-1,0.1-1.5v-0.6h1.3v0.5c0,0.7-0.1,1.3-0.2,2c-0.1,0.5-0.4,1-0.8,1.4C-8225.5-4915.7-8226.2-4915.6-8226.8-4915.6z"
					/>
					<path
						class="st0"
						d="M-8217.3-4915.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c1.1-0.7,2.5-0.7,3.6,0c0.5,0.4,0.8,0.9,0.9,1.4c0.2,0.8,0.3,1.5,0.3,2.3v3.1
              c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8216.1-4915.7-8216.7-4915.6-8217.3-4915.6z M-8217.3-4916.7
              c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6c0-0.4-0.2-0.8-0.4-1.1
              c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3c0,0.5,0,1.1,0.1,1.6
              c0,0.4,0.2,0.8,0.4,1.1C-8218.1-4916.8-8217.7-4916.6-8217.3-4916.7z"
					/>
				</g>
				<g transform="translate(8279.902 4965.921)">
					<path
						class="st0"
						d="M-8252.1-4915.6c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.8-0.8-1-1.4c-0.2-0.8-0.3-1.7-0.3-2.6v-2.8
              c0-0.9,0.1-1.8,0.3-2.6c0.2-0.6,0.5-1,1-1.3c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.3,0.1,1.8,0.5c0.4,0.4,0.8,0.9,0.9,1.5
              c0.2,0.9,0.3,1.7,0.2,2.6v1h-4.5v2c0,0.6,0,1.2,0.2,1.7c0.1,0.4,0.3,0.7,0.5,0.9c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.5-0.1,0.8-0.2
              c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.4,0.2-0.9,0.2-1.3v-0.8h1.4v0.6c0,0.9-0.2,1.7-0.6,2.5
              C-8250.4-4915.9-8251.3-4915.5-8252.1-4915.6z M-8253.7-4922.3h3.1v-0.9c0-0.5,0-1-0.1-1.5c0-0.4-0.2-0.7-0.4-1
              c-0.3-0.2-0.6-0.4-1-0.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c-0.1,0.6-0.2,1.2-0.2,1.9L-8253.7-4922.3z"
					/>
					<path
						class="st0"
						d="M-8246.2-4915.8l2.7-5.9l-2.5-5.4h1.4l1.9,4.1l1.8-4.1h1.4l-2.6,5.5l2.7,5.7h-1.4l-2.1-4.4l-2,4.4
              L-8246.2-4915.8z"
					/>
					<path
						class="st0"
						d="M-8236.1-4912.1v-14.9h1.4v0.9c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.4,1.3-0.3c0.5,0,0.9,0.1,1.3,0.4
              c0.3,0.3,0.6,0.6,0.8,1c0.2,0.5,0.3,0.9,0.4,1.4c0.1,0.5,0.1,1.1,0.1,1.6v2.4c0,0.8-0.1,1.7-0.2,2.5c-0.1,0.6-0.4,1.2-0.8,1.6
              c-0.4,0.4-1,0.6-1.6,0.6c-0.4,0-0.8-0.1-1.2-0.4c-0.3-0.2-0.6-0.4-0.8-0.7v4.6L-8236.1-4912.1z M-8232.9-4916.7
              c0.4,0,0.7-0.1,1-0.4c0.2-0.4,0.4-0.8,0.4-1.2c0.1-0.6,0.1-1.3,0.1-2v-2.4c0-0.6,0-1.2-0.1-1.8c0-0.4-0.2-0.8-0.4-1.1
              c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.6v7.6c0.2,0.2,0.5,0.4,0.8,0.6
              C-8233.6-4916.8-8233.2-4916.7-8232.9-4916.7L-8232.9-4916.7z"
					/>
					<path class="st0" d="M-8225.9-4915.8v-15.7h1.4v15.7H-8225.9z" />
					<path
						class="st0"
						d="M-8217.4-4915.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8216.1-4915.7-8216.8-4915.6-8217.4-4915.6z
                M-8217.4-4916.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8218.2-4916.8-8217.8-4916.6-8217.4-4916.7L-8217.4-4916.7z"
					/>
					<path
						class="st0"
						d="M-8210.4-4915.8v-11.2h1.4v1.5c0.3-0.5,0.7-1,1.2-1.3c0.4-0.2,0.9-0.4,1.3-0.4h0.1c0,0,0.1,0,0.1,0v1.5
              c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.7,0.6-0.9,1v8.6L-8210.4-4915.8z"
					/>
					<path
						class="st0"
						d="M-8200.1-4915.6c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.8-0.8-1-1.4c-0.2-0.8-0.3-1.7-0.3-2.6v-2.8
              c0-0.9,0.1-1.8,0.3-2.6c0.2-0.6,0.5-1,1-1.3c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.3,0.1,1.8,0.5c0.4,0.4,0.8,0.9,0.9,1.5
              c0.2,0.9,0.3,1.7,0.2,2.6v1h-4.5v2c0,0.6,0,1.2,0.2,1.7c0.1,0.4,0.3,0.7,0.5,0.9c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.5-0.1,0.8-0.2
              c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.4,0.2-0.9,0.2-1.3v-0.8h1.4v0.6c0,0.9-0.2,1.7-0.6,2.5
              C-8198.3-4915.9-8199.2-4915.5-8200.1-4915.6z M-8201.7-4922.3h3.1v-0.9c0-0.5,0-1-0.1-1.5c0-0.4-0.2-0.7-0.4-1
              c-0.3-0.2-0.6-0.4-1-0.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.2-0.5,0.5-0.5,0.9c-0.1,0.6-0.2,1.2-0.2,1.9L-8201.7-4922.3z"
					/>
				</g>
				<g transform="translate(8281.883 4990.233)">
					<path
						class="st0"
						d="M-8255.1-4913.1v-14.9h1.4v0.9c0.2-0.3,0.5-0.6,0.9-0.8c0.4-0.2,0.8-0.4,1.3-0.3c0.5,0,0.9,0.1,1.3,0.4
              c0.3,0.3,0.6,0.6,0.8,1c0.2,0.5,0.3,0.9,0.4,1.4c0.1,0.5,0.1,1.1,0.1,1.6v2.4c0,0.8-0.1,1.7-0.2,2.5c-0.1,0.6-0.4,1.2-0.8,1.6
              c-0.4,0.4-1,0.6-1.6,0.6c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.4-0.8-0.7v4.6L-8255.1-4913.1z M-8251.9-4917.8
              c0.4,0,0.7-0.1,1-0.4c0.2-0.4,0.4-0.8,0.4-1.2c0.1-0.6,0.1-1.3,0.1-2v-2.4c0-0.6,0-1.2-0.1-1.8c0-0.4-0.2-0.8-0.4-1.1
              c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.5,0.4-0.7,0.6v7.6c0.2,0.2,0.5,0.4,0.8,0.6
              C-8252.6-4917.8-8252.3-4917.7-8251.9-4917.8L-8251.9-4917.8z"
					/>
					<path
						class="st0"
						d="M-8245-4916.8v-11.2h1.4v1.5c0.3-0.5,0.7-1,1.2-1.3c0.4-0.2,0.9-0.4,1.3-0.4h0.1c0,0,0.1,0,0.1,0v1.5
              c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0-0.4,0c-0.4,0-0.8,0.1-1.2,0.3c-0.4,0.2-0.7,0.6-0.9,1v8.6L-8245-4916.8z"
					/>
					<path
						class="st0"
						d="M-8234.7-4916.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8233.4-4916.8-8234-4916.6-8234.7-4916.6z
                M-8234.7-4917.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8235.5-4917.8-8235.1-4917.7-8234.7-4917.7L-8234.7-4917.7z"
					/>
					<path
						class="st0"
						d="M-8228.4-4913.9c-0.1,0-0.2,0-0.4,0c-0.1,0-0.2,0-0.4-0.1v-1.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.2,0
              c0.3,0,0.6-0.1,0.9-0.2c0.2-0.1,0.3-0.4,0.4-0.6c0.1-0.2,0.1-0.5,0.1-0.7v-11.5h1.4v11.6c0.1,0.7-0.2,1.4-0.7,2
              C-8227.1-4914-8227.8-4913.8-8228.4-4913.9z M-8227.4-4929.6v-1.9h1.4v1.9L-8227.4-4929.6z"
					/>
					<path
						class="st0"
						d="M-8218.9-4916.6c-0.6,0-1.2-0.1-1.7-0.4c-0.5-0.3-0.8-0.8-1-1.4c-0.2-0.8-0.3-1.7-0.3-2.6v-2.8
              c0-0.9,0.1-1.8,0.3-2.6c0.2-0.6,0.5-1,1-1.3c0.5-0.3,1.1-0.4,1.7-0.4c0.6,0,1.3,0.1,1.8,0.5c0.4,0.4,0.8,0.9,0.9,1.5
              c0.2,0.9,0.3,1.7,0.2,2.6v1h-4.5v2c0,0.6,0,1.2,0.2,1.7c0.1,0.4,0.3,0.7,0.5,0.9c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.5-0.1,0.8-0.2
              c0.3-0.1,0.5-0.4,0.6-0.6c0.2-0.4,0.2-0.9,0.2-1.3v-0.8h1.4v0.6c0,0.9-0.2,1.7-0.6,2.5C-8217.2-4916.9-8218-4916.5-8218.9-4916.6z
                M-8220.5-4923.4h3.1v-0.9c0-0.5,0-1-0.1-1.5c0-0.4-0.2-0.7-0.4-1c-0.3-0.2-0.6-0.4-1-0.3c-0.3,0-0.7,0.1-0.9,0.3
              c-0.3,0.2-0.5,0.5-0.5,0.9c-0.1,0.6-0.2,1.2-0.2,1.9L-8220.5-4923.4z"
					/>
					<path
						class="st0"
						d="M-8209.3-4916.6c-1.4,0.2-2.6-0.8-2.8-2.1c0,0,0,0,0,0c-0.1-0.7-0.2-1.5-0.2-2.3v-2.6c0-0.9,0.1-1.7,0.3-2.6
              c0.1-0.6,0.4-1.1,0.9-1.5c1.1-0.6,2.5-0.6,3.7,0c0.4,0.3,0.7,0.8,0.8,1.3c0.2,0.6,0.2,1.2,0.2,1.9v0.5h-1.3v-0.5
              c0-0.5,0-1-0.1-1.5c-0.1-0.3-0.2-0.6-0.5-0.8c-0.3-0.2-0.6-0.2-0.9-0.2c-0.4,0-0.7,0.1-1,0.3c-0.3,0.3-0.4,0.6-0.5,1
              c-0.1,0.6-0.1,1.3-0.1,1.9v3.1c-0.1,0.8,0.1,1.6,0.3,2.4c0.2,0.5,0.8,0.7,1.3,0.7c0.4,0,0.7-0.1,1-0.3c0.2-0.2,0.4-0.6,0.4-0.9
              c0.1-0.5,0.1-1,0.1-1.5v-0.6h1.3v0.5c0,0.7-0.1,1.3-0.2,2c-0.1,0.5-0.4,1-0.8,1.4C-8208-4916.8-8208.7-4916.6-8209.3-4916.6z"
					/>
					<path
						class="st0"
						d="M-8200-4916.7c-0.4,0-0.9-0.1-1.3-0.3c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.4-0.2-0.8-0.2-1.3v-8h-1.4v-0.9h1.4
              v-3.5h1.4v3.5h1.9v0.9h-1.9v7.8c0,0.4,0,0.8,0.2,1.2c0.1,0.2,0.4,0.3,0.8,0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4-0.1v1.1
              c-0.2,0-0.4,0.1-0.6,0.1C-8199.6-4916.7-8199.8-4916.7-8200-4916.7z"
					/>
				</g>
				<g transform="translate(8255.145 5013.517)">
					<path
						class="st0"
						d="M-8255.1-4929.6v-1.9h1.4v1.9H-8255.1z M-8255.1-4916.8v-11.2h1.4v11.2H-8255.1z"
					/>
					<path
						class="st0"
						d="M-8249.4-4916.8v-11.2h1.4v1.2c0.4-0.4,0.8-0.7,1.2-1c0.5-0.3,1-0.4,1.5-0.4c0.3,0,0.7,0.1,1,0.3
              c0.3,0.2,0.5,0.5,0.6,0.8c0.1,0.4,0.2,0.9,0.2,1.3v9h-1.4v-8.7c0-0.4-0.1-0.9-0.3-1.2c-0.2-0.2-0.5-0.4-0.8-0.4
              c-0.4,0-0.7,0.1-1,0.3c-0.4,0.2-0.7,0.5-1,0.8v9.2L-8249.4-4916.8z"
					/>
					<path
						class="st0"
						d="M-8239.5-4916.8v-11.2h1.4v1.2c0.4-0.4,0.8-0.7,1.2-1c0.5-0.3,1-0.4,1.5-0.4c0.3,0,0.7,0.1,1,0.3
              c0.3,0.2,0.5,0.5,0.6,0.8c0.1,0.4,0.2,0.9,0.2,1.3v9h-1.4v-8.7c0-0.4-0.1-0.9-0.3-1.2c-0.2-0.2-0.5-0.4-0.8-0.4
              c-0.3,0-0.7,0.1-1,0.3c-0.4,0.2-0.7,0.5-1,0.8v9.2L-8239.5-4916.8z"
					/>
					<path
						class="st0"
						d="M-8226.9-4916.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8225.6-4916.8-8226.3-4916.6-8226.9-4916.6z
                M-8226.9-4917.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8227.7-4917.8-8227.3-4917.7-8226.9-4917.7z"
					/>
					<path class="st0" d="M-8218.3-4916.8l-2.4-11.2h1.4l1.8,9.6l1.8-9.6h1.4l-2.3,11.2H-8218.3z" />
					<path
						class="st0"
						d="M-8209-4916.6c-0.4,0-0.8-0.1-1.2-0.3c-0.3-0.2-0.6-0.5-0.8-0.9c-0.2-0.4-0.3-0.8-0.3-1.3
              c0-0.5,0.1-1,0.2-1.5c0.2-0.4,0.4-0.8,0.8-1.1c0.4-0.4,0.9-0.7,1.4-0.9c0.6-0.3,1.3-0.6,2.1-1v-0.8c0-0.5,0-1.1-0.1-1.6
              c0-0.3-0.2-0.6-0.4-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.5,0.1-0.8,0.2c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.4-0.2,0.8-0.2,1.2v0.4
              l-1.4,0c-0.1-0.9,0.2-1.8,0.7-2.5c0.6-0.6,1.5-0.9,2.3-0.8c0.8-0.1,1.6,0.3,2.1,0.9c0.5,0.9,0.7,1.8,0.6,2.8v5.5
              c0,0.2,0,0.4,0,0.7s0,0.6,0,0.9s0,0.5,0,0.6h-1.3c0-0.2-0.1-0.5-0.1-0.8s-0.1-0.5-0.1-0.7c-0.2,0.5-0.5,0.9-0.8,1.2
              C-8207.9-4916.8-8208.4-4916.6-8209-4916.6z M-8208.5-4917.8c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.4-0.3,0.6-0.5
              c0.2-0.2,0.3-0.4,0.4-0.6v-3.5c-0.6,0.3-1,0.5-1.4,0.8c-0.3,0.2-0.7,0.4-1,0.7c-0.2,0.2-0.4,0.5-0.5,0.7c-0.1,0.3-0.2,0.6-0.2,1
              c0,0.5,0.1,0.9,0.4,1.3C-8209.2-4918-8208.9-4917.8-8208.5-4917.8L-8208.5-4917.8z"
					/>
					<path
						class="st0"
						d="M-8198.7-4916.7c-0.4,0-0.9-0.1-1.3-0.3c-0.3-0.2-0.5-0.5-0.6-0.9c-0.1-0.4-0.2-0.8-0.2-1.3v-8h-1.4v-0.9h1.4
              v-3.5h1.4v3.5h1.9v0.9h-1.9v7.8c0,0.4,0,0.8,0.2,1.2c0.1,0.2,0.4,0.3,0.8,0.3c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4-0.1v1.1
              c-0.2,0-0.4,0.1-0.6,0.1C-8198.3-4916.7-8198.5-4916.7-8198.7-4916.7z"
					/>
					<path
						class="st0"
						d="M-8193.8-4929.6v-1.9h1.4v1.9H-8193.8z M-8193.8-4916.8v-11.2h1.4v11.2H-8193.8z"
					/>
					<path
						class="st0"
						d="M-8185.3-4916.6c-0.6,0-1.3-0.1-1.8-0.5c-0.5-0.4-0.8-0.9-0.9-1.4c-0.2-0.8-0.3-1.5-0.3-2.3v-3.1
              c0-0.8,0.1-1.5,0.3-2.3c0.1-0.6,0.5-1.1,0.9-1.4c0.5-0.4,1.2-0.5,1.8-0.5c0.6,0,1.3,0.1,1.8,0.5c0.5,0.4,0.8,0.9,0.9,1.4
              c0.2,0.8,0.3,1.5,0.3,2.3v3.1c0,0.8-0.1,1.5-0.3,2.3c-0.1,0.6-0.5,1.1-0.9,1.4C-8184-4916.8-8184.7-4916.6-8185.3-4916.6z
                M-8185.3-4917.7c0.4,0,0.8-0.1,1.1-0.4c0.2-0.3,0.4-0.7,0.4-1.1c0.1-0.5,0.1-1.1,0.1-1.6v-3.3c0-0.5,0-1.1-0.1-1.6
              c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.7-0.4-1.1-0.4c-0.4,0-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.1c-0.1,0.5-0.1,1.1-0.1,1.6v3.3
              c0,0.5,0,1.1,0.1,1.6c0,0.4,0.2,0.8,0.4,1.1C-8186.1-4917.8-8185.7-4917.7-8185.3-4917.7z"
					/>
					<path
						class="st0"
						d="M-8178.4-4916.8v-11.2h1.4v1.2c0.4-0.4,0.8-0.7,1.2-1c0.5-0.3,1-0.4,1.5-0.4c0.3,0,0.7,0.1,1,0.3
              c0.3,0.2,0.5,0.5,0.6,0.8c0.1,0.4,0.2,0.9,0.2,1.3v9h-1.4v-8.7c0-0.4-0.1-0.9-0.3-1.2c-0.2-0.2-0.5-0.4-0.8-0.4
              c-0.4,0-0.7,0.1-1,0.3c-0.4,0.2-0.7,0.5-1,0.8v9.2L-8178.4-4916.8z"
					/>
				</g>
			</g>
			<g id="">
				<g transform="translate(8549.963 4957.899)">
					<path
						class="st0"
						d="M-8239.2-4917.1h13.2l2.4,4.9h-15.6v22.1c0,8.6,2,11,7.3,11c3.6,0,7-2,8.8-5.2l0.9,1.8
              c-4.3,7-9.1,10.6-16.2,10.6c-6.2,0-11.5-3.6-11.5-13.8v-26.4l-5.1-10.4l15.9-8.9L-8239.2-4917.1z"
					/>
				</g>
				<g transform="translate(8590.654 4971.258)">
					<path
						class="st0"
						d="M-8235.7-4885.3c-12.8,0-19.5-10.3-19.5-21.1c0-11.6,7.8-25.1,24.5-25.1c12.8,0,19.5,10.3,19.5,21.1
              C-8211.1-4898.8-8218.9-4885.3-8235.7-4885.3 M-8243.7-4914.6c0,14,5.1,25.9,13,25.9c6.6,0,8.4-8.8,8.4-13.5
              c0-14-5.2-25.9-13.1-25.9C-8242.9-4928.1-8243.7-4920-8243.7-4914.6"
					/>
				</g>
				<g transform="translate(8641.697 4969.75)">
					<path
						class="st0"
						d="M-8221.7-4884.9v-27.7c0-6.9-1-11.3-7.9-11.3c-3.3,0-6.7,1.4-9.6,5.3v33.7h-10.8v-27.3l-5.1-10.4l15.9-8.9
              v9.9c3.2-5.1,8.8-8.2,14.8-8.4c9.2,0,13.5,5.9,13.5,14.4v30.7L-8221.7-4884.9z"
					/>
				</g>
				<g transform="translate(8697.499 4971.183)">
					<path
						class="st0"
						d="M-8215.9-4911.8h-28.9c1,11.6,7.2,16.5,14.2,16.5c6.1,0,11.5-3.1,12.9-6.9l1.4,0.5
              c-3.2,8.2-9.6,16.5-20.4,16.5c-11.8,0-18.5-10.5-18.5-21.4c0-11.6,7.2-25,21.9-25c10.7,0,17.3,7.2,17.4,19.4L-8215.9-4911.8
              L-8215.9-4911.8L-8215.9-4911.8z M-8227.5-4915.4c0-9.6-3.9-13.4-7.8-13.4c-6.8,0-9.6,8.2-9.6,13.6v0.6h17.5V-4915.4z"
					/>
				</g>
				<g transform="translate(8507.032 4971.242)">
					<path
						class="st0"
						d="M-8225.2-4928.8c-3.9-1.7-8.1-2.6-12.4-2.7c-4.4,0-17.5,1-17.5,14.5s23.1,10.7,23.1,24c0,2.8-2.5,4.1-4.3,4.6
              c-0.9,0.3-1.8,0.4-2.7,0.4h-2.5l-13.7-18.1v18.1c0,0,8.5,2.7,15.5,2.7c3.6,0,18.1-1.4,18.1-15.2s-24.1-13-24.1-22.9
              c0-2.2,1.4-4.2,3.6-4.8c1-0.4,2.1-0.6,3.1-0.6h3l10.7,15.1L-8225.2-4928.8z"
					/>
				</g>
			</g>
			<g id="">
				<g transform="translate(8398.24 4957.861)">
					<path
						class="st0"
						d="M-8224.8-4904.8c6.3-2.4,13.5-6.2,13.5-13.8c0-10.2-9.7-12.9-24.1-12.9h-3.3l-16.4,9.2l5.3,10.7l0,38.6h13.7
              c12.1,0,28.6-3.3,28.6-15.6C-8207.5-4897.3-8214.1-4902.4-8224.8-4904.8 M-8235.8-4928.8c5.9,0,13.5,1.6,13.5,11.9
              c0,6.7-5.5,11.6-16.4,11.6v-23.5H-8235.8z M-8236.1-4876.3h-2.6v-26.8c10.9,0,18.6,3,18.6,14.3
              C-8220.1-4880.7-8227.4-4876.3-8236.1-4876.3"
					/>
				</g>
				<g transform="translate(8367.255 4931.491)">
					<path
						class="st0"
						d="M-8255.1-4931.5v111.2h94.4l16.9-16.9v-94.4L-8255.1-4931.5z M-8247.5-4827.9v-96h96v83.6l0,0l-12.3,12.3l0,0
              H-8247.5z"
					/>
				</g>
			</g>
		</svg>
	</div>
</template>

<script>
	export default {
		name: "preloader",
		data() {
			return {
				name: {
					type: String,
					default: "Home"
				}
			};
		},
		created() {
			this.name = this.$route.name;
		}
	};
</script>
<style lang="scss">
	.loader {
		background: #dedede;
		position: fixed;
		z-index: 99999;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		-webkit-transition: all 0.3s cubic-bezier(0, 0.5, 0, 1);
		-moz-transition: all 0.3s cubic-bezier(0, 0.5, 0, 1);
		-o-transition: all 0.3s cubic-bezier(0, 0.5, 0, 1);
		transition: all 0.3s cubic-bezier(0, 0.5, 0, 1);

		#animatedlogo {
			position: fixed;
			top: calc(50% - 50px);
			left: 50%;
			margin-left: -255px;
			z-index: 2;
			fill: #ffffff;
			overflow: visible;
			width: 510px;

			-webkit-animation: changeLogos 2s ease-in-out 3s forwards;
			-moz-animation: changeLogos 2s ease-in-out 3s forwards;
			-o-animation: changeLogos 2s ease-in-out 3s forwards;
			animation: changeLogos 2s ease-in-out 3s forwards;

			& #B {
				transform: translateX(18%);
				opacity: 1;
				-webkit-animation: myanimation1 2s 1s ease-in-out forwards;
				-moz-animation: myanimation1 2s 1s ease-in-out forwards;
				-o-animation: myanimation1 2s 1s ease-in-out forwards;
				animation: myanimation1 2s 1s ease-in-out forwards;
			}

			& #Stone {
				transform: translateX(50%);
				opacity: 0;
				-webkit-animation: myanimation2 2s 1s ease-in-out forwards;
				-moz-animation: myanimation2 2s 1s ease-in-out forwards;
				-o-animation: myanimation2 2s 1s ease-in-out forwards;
				animation: myanimation2 2s 1s ease-in-out forwards;
			}

			& #Rreferencias {
				transform: translateX(-50%);
				opacity: 0;
				-webkit-animation: myanimation3 2s 1s ease-in-out forwards;
				-moz-animation: myanimation3 2s 1s ease-in-out forwards;
				-o-animation: myanimation3 2s 1s ease-in-out forwards;
				animation: myanimation3 2s 1s ease-in-out forwards;
			}
		}
	}

	#rotateElement {
		transform-origin: center;
		transform-box: 200px 200px;
		transform: rotate(20deg);
	}

	@media (max-width: 812px) {
		.loader #animatedlogo {
			transform: scale(0.8);
			margin-left: -241px;
		}
	}
</style>