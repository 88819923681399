<template>
	
	<section
		id="feautedproductList "
		class="feautedproductList container"		
	>
		<div class="row">
			
			 <div class="col-12 text-center titlehomefeatured">
				{{ home.featured_text1 }} <br/>
				<span>{{ home.featured_text2 }}</span>				
			</div> 

			<div
					class="col-12 col-lg-11 productsContainer featuredCenter"					
					itemscope
					itemtype="http://schema.org/ItemList"					
					
				>
				<div class="row">
					<template v-for="(featured_product,index) in products" >
						

						
							
								<router-link 
									class="rellaxProductF product col-12 col-lg-6 col-xl-4 "
									itemprop="itemListElement"
									itemscope
									itemtype="http://schema.org/Product"
									
									
									:to="{
										path:
										'/bloco-b/category/' +
										featured_product.category +
										'/collection/' +
										featured_product.collection +
										'/id/' +
										featured_product.id +
										'/name/' +
										featured_product.firstname +
										'-' +
										featured_product.secondname,
									}"		
									:data-rellax-speed="getDataSpeed(index)"
														
									
								>
									
									<meta itemprop="position" :content="index" />
									<a
										:href="
											'/bloco-b/category/' +
												featured_product.category +
												'/collection/' +
												featured_product.collection +
												'/id/' +
												featured_product.id +
												'/name/' +
												featured_product.firstName +
												'-' +
												featured_product.secondName
											"
										itemprop="url"
									></a>
									<meta itemprop="sku" :content="featured_product.id" /> 

									<div itemprop="offers" itemtype="https://schema.org/Offer" itemscope>
										<span itemprop="priceCurrency" content="EUR"></span>
										<link itemprop="availability" href="https://schema.org/InStock" />
									</div>

									<div class="containerImage">
										<img
											:src="featured_product.imagem"
											class="productImage"
											width="300"
											height="300"
											
											itemprop="image"
										/>
									</div>
									<p class="productName" itemprop="name">
										<mark>
											<span></span>
											{{ featured_product.firstname }}
											<br />
											{{ featured_product.secondname }}
										</mark>
									</p>
									
								
									
									</router-link>
								
								
							
						
						
					</template>
					</div>
				</div>
			</div>
			
			<a
				@click="loadMoreClick"
				v-if="hasPaging && hasLink"
				style="display: block; text-align: center; padding: 80px 0 100px;"
			>
				<seeMoreButton>{{ $t("actions_seemore") }}</seeMoreButton>
			</a>

			<router-link
				to="/"
				v-if="!hasPaging && hasLink"
				style="display: block; text-align: center; padding: 80px 0 100px;"
			>
				<seeMoreButton>{{ $t("actions_loadmore") }}</seeMoreButton>
			</router-link>
	</section>

	
</template>

<script>
	import seeMoreButton from "@/components/ui/seeMoreButton";
	export default {
		name: "Listpage",
		components: {
			seeMoreButton
		},
		data() {
			return {
					products: [],
					featured_products: [],
					currentPage: 1,
					itemsPerPage: 3,
					hasPaging: true,
					hasLink: true,
					rellax: null,
					home: []
				};
		},
		beforeMount() {
			if (this.$route.params) {
				this.selectedCategory = this.$route.params.category;
				this.selectedCollection = this.$route.params.collection;
				
				
			}
			
		},
		methods: {			
			getDataSpeed(index) {
				// if (this.hasFeaturedProducts) 
				return 0;
				// return index % 3 == 1 ? 2 : -2;
			},
			loadMoreClick() {
				let slice = this.featured_products.slice(
					this.currentPage * this.itemsPerPage,
					(this.currentPage + 1) * this.itemsPerPage
				);
				this.products = this.products.concat(slice);
				this.currentPage++;

				
				if (
					Math.round(this.featured_products.length / this.itemsPerPage) == this.currentPage
				)
				this.hasLink = false;

				console.log(Math.round(this.featured_products.length / this.itemsPerPage))
			}
		},
		// mounted() {
		// 	this.$http
		// 		.get(
		// 			"https://www.bstone.pt/webservices/" + this.$i18n.locale + "/homepage2"
		// 		)
		// 		.then(response => {					
		// 			this.featured_products = response.data.featured_products;
		// 			this.home = response.data;
		// 		});
		// },
		created() {
			this.itemsPerPage = this.$parent.productsAmount;
			this.hasPaging = this.$parent.hasPaging;

			this.$http
				.get(
					"https://www.bstone.pt/webservices/" + this.$i18n.locale + "/homepage2"
				)
				.then(response => {
					this.featured_products = response.data.featured_products;
					this.products = this.featured_products.slice(0, this.itemsPerPage);

					this.home = response.data;

					this.$eventBus.$emit("componentFinishLoad", true);
				});
		}
	
	};
	


</script>
<style lang="scss">
	
	@media (min-width: 1200px){
		.container-xl, .container-lg, .container-md, .container-sm, .productList.container {
			max-width: 1140px;
		}
	}
	#homepage .parallaxContainer:after {
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		content: "";
		display: block;
		z-index: 2;
		background: none; 
		height: 130px;
	}
	
	.titlehomefeatured{
		font-family: 'Oswald';
		font-size: 40px;
		margin-bottom: 60px;
		text-transform: lowercase;
	}
	.titulo1,.titulo2{
		font-family: "Noe Display", serif;
		font-size: 40px;
	}
	.titulo2{
		line-height: 0.5;
	}
	.colecao p{
		margin-top: 30px;
    	font-size: 22px;
	}
	
	.filtro_cor{
		list-style: none;
		position: absolute;
		margin: 0;
		margin-top: 350px;
		padding: 0;
		z-index: 1;
	}
	.filtro_cor a span.color {
    width: 22px;
    height: 22px;
    display: inline-flex;
    transform: rotate(45deg);
    transform-origin: 50% 50%;
    // will-change: transform,background-color;
    // transition: transform .5s ease,background-color .3s ease;
	}
	.descriptionCollectionContent{
		background-color: white;
		padding-top: 60px;
		padding-left: 25%;
		padding-right: 25%;
    	z-index: 0;
		padding-bottom: 5%;
		margin-top: -22px;
		-webkit-transition: all 0.5s ease 0.2s;
			-moz-transition: all 0.5s ease 0.2s;
			-o-transition: all 0.5s ease 0.2s;
			transition: all 0.5s ease 0.2s;
	}
	.categoria{
		color: #c47c5a;
		font-size: 20px;
		letter-spacing: 4px;
		text-transform: uppercase;
		font-family: 'Oswald', sans-serif;
		padding-bottom: 10px;
	}
	.colecao {
		font-size: 22px;
		padding-top: 10px;
		
	}
	.tituloCollections{
		color:#333333;
		font-family: 'Oswald';
		letter-spacing: 2.4px;
		text-transform: uppercase;
		font-weight: 600;
		font-size: 24px;
		margin-bottom: 5%;
	}
	.clear_filters{
		font-size: 16px;
		text-transform: uppercase;
		color: #969696;
		font-family: 'Oswald';
	}
	.clear_filters:hover{
		color: #333;
		text-decoration: underline;
	}
	.collectionMenu li a::before{
		display: none;
	}

	.productsContainer.row{
		padding-bottom: 15%;
	}


	.feautedproductList {
		// background-color: white;
		min-height: 100vh;
		padding-top: 50px;
		// margin-bottom: 13%;

		&.notFeaturedProducts {
			padding-top: 145px;
			// margin-bottom: 25%;

			.productsContainer {
				// margin-top: 300px;
				margin-top: 150px;
				// padding-left: 25%;
				z-index: 0;
			}

			h1.pageTitle {
				position: fixed;
				margin-left: 0;
				font-size: 10rem;
				//z-index: 2;
			}
		}

		.productsContainer.featuredCenter {
			margin: 0 auto;
			max-width: 1100px;
		}

		.filters {
			margin-top: 150px;
			position: relative;
			z-index: 1;
			-webkit-transition: all 0.5s ease 0.2s;
			-moz-transition: all 0.5s ease 0.2s;
			-o-transition: all 0.5s ease 0.2s;
			transition: all 0.5s ease 0.2s;

			.categoryMenu,
			.collectionMenu,
			.colorFilters {
				list-style: none;
				// position: fixed;
				// position: absolute;
				// top: 39vh; //450px;
				
				margin: 0;
				// margin-top: 150px;
				padding: 0;
				z-index: 1;
			}
			.colorFilters{
				margin-top: 90px;
			}
			.categoryMenu {
				li {
					a {
						display: block;
						font-family: "Oswald", sans-serif;
						font-size: 1.25rem;
						letter-spacing: 1px;
						font-weight: 400;
						color: #333;
						line-height: 2rem;
						text-transform: uppercase;
						margin: 10px 0;
						will-change: color, margin;
						-webkit-transition: color 0.5s ease, margin 0.5s ease;
						-moz-transition: color 0.5s ease, margin 0.5s ease;
						-o-transition: color 0.5s ease, margin 0.5s ease;
						transition: color 0.5s ease, margin 0.5s ease;
					}

					&:hover {
						a {
							color: #b7b7b7;
						}
					}

					&.active {
						a {
							margin: 10px 0 110px;
							color: #b7b7b7;
						}
					}
				}
			}

			.collectionMenu {
				will-change: top;
				-webkit-transition: top 0.5s ease;
				-moz-transition: top 0.5s ease;
				-o-transition: top 0.5s ease;
				transition: top 0.5s ease;

				&.cat-0 {
					top: 44vh; //500px;
				}
				&.cat-1 {
					top: 49vh; //540px;
				}
				&.cat-2 {
					top: 54vh; //580px;
				}

				li {
					a {
						display: block;
						position: relative;
						font-family: "Oswald", sans-serif;
						// font-size: 1rem;
						font-weight: 200;
						color: #333;
						line-height: 1.5rem;
						text-transform: uppercase;
						margin: 10px 0;
						// padding-left: 60px;
						font-weight: 100;
						font-size: 20px;

						&:before {
							position: absolute;
							top: 50%;
							left: 0;
							content: "";
							width: 30px;
							height: 1px;
							background-color: #333;
							will-change: width, background-color;
							-webkit-transition: width 0.5s ease, background-color 0.3s ease;
							-moz-transition: width 0.5s ease, background-color 0.3s ease;
							-o-transition: width 0.5s ease, background-color 0.3s ease;
							transition: width 0.5s ease, background-color 0.3s ease;
						}
					}

					&.active a,
					& > a:hover {
						color: #c47c5a;
						font-weight: 400;
						&:before {
							background-color: #c47c5a;
							width: 43px;
						}
					}
				}
			}

			.colorFilters {
				top: 200px; //750px;
				// bottom: 5vh;
				z-index: 0;
				// margin-left: 5px;

				a {
					height: 40px;
					display: flex;
					align-items: center;

					span.name {
						font-family: "Oswald", sans-serif;
						font-size: 18px;
						font-weight: 200;
						color: #6a6a6a;
						padding-left: 15px;
						position: relative;
						will-change: padding, color;
						-webkit-transition: color 0.3s ease, padding 0.5s ease;
						-moz-transition: color 0.3s ease, padding 0.5s ease;
						-o-transition: color 0.3s ease, padding 0.5s ease;
						transition: color 0.3s ease, padding 0.5s ease;

						&:before {
							position: absolute;
							top: 15px;
							left: 10px;
							content: "";
							width: 0;
							height: 1px;
							background-color: #6a6a6a;
							will-change: width, background-color;
							-webkit-transition: width 0.5s ease, background-color 0.3s ease;
							-moz-transition: width 0.5s ease, background-color 0.3s ease;
							-o-transition: width 0.5s ease, background-color 0.3s ease;
							transition: width 0.5s ease, background-color 0.3s ease;
						}
					}

					span.color {
						width: 22px;
						height: 22px;
						display: inline-flex;
						-webkit-transform: rotate(45deg);
						-ms-transform: rotate(45deg);
						transform: rotate(45deg);
						transform-origin: 50% 50%;
						will-change: transform, background-color;
						-webkit-transition: transform 0.5s ease, background-color 0.3s ease;
						-moz-transition: transform 0.5s ease, background-color 0.3s ease;
						-o-transition: transform 0.5s ease, background-color 0.3s ease;
						transition: transform 0.5s ease, background-color 0.3s ease;
					}

					&:hover,
					&.active {
						span.name {
							color: #333;
							padding-left: 30px;

							&:before {
								background-color: #333;
								width: 15px;
							}
						}

						span.color {
							-webkit-transform: rotate(225deg);
							-ms-transform: rotate(225deg);
							transform: rotate(225deg);
						}
					}
				}
			}
		}

		.product {
			position: relative;
			text-decoration: none;
			// -webkit-transform:  translateZ(.25px);
			// -ms-transform:      translateZ(.25px);
			// transform:          translateZ(.25px);

			.containerImage {
				width: 260px;
				height: 373px;
				margin: 0 auto;
				overflow: hidden;
			}

			.productImage {
				width: 373px;
				height: 373px;
				overflow: hidden;
				will-change: transform;
				-webkit-transition: transform 0.5s ease;
				-moz-transition: transform 0.5s ease;
				-o-transition: transform 0.5s ease;
				transition: transform 0.5s ease;

				img {
					width: 100%;
				}
			}

			.productName {
				width: 140px;
				position: absolute;
				right: -20px;
				top: 270px;
				z-index: 2;

				font-family: "Noe Display", serif;
				font-weight: normal;
				font-size: 25px;
				line-height: 30px;
				color: #333;
				text-align: left;
				text-indent: 25px;
				will-change: top;
				-webkit-transition: top 0.5s ease;
				-moz-transition: top 0.5s ease;
				-o-transition: top 0.5s ease;
				transition: top 0.5s ease;

				span {
					background: #333;
					height: 5px;
					width: 32px;
					display: block;
					content: "";
					position: absolute;
					top: 10px;
					left: -10px;
				}

				mark {
					background-color: white;
					padding: 2px 5px;
				}
			}

			.categoryName {
				font-family: "Oswald", sans-serif;
				font-size: 15px;
				color: #333;
				text-transform: uppercase;
				letter-spacing: 1.5px;
				text-align: center;
				margin: 5px;
			}

			&:nth-of-type(3n + 2) {
				margin-top: 130px;
				-webkit-transform: translateZ(0.7px) scale(1);
				-ms-transform: translateZ(0.7px) scale(1);
				transform: translateZ(0.7px) scale(1);
			}

			&:nth-of-type(3n + 2) .productName {
				top: 52px;
			}

			&:hover .productImage {
				-webkit-transform: scale(1.1);
				-ms-transform: scale(1.1);
				transform: scale(1.1);
			}
			&:hover .productName {
				top: 170px;
			}
		}
	}

	@media (max-height: 860px) {
		.feautedproductList .filters .colorFilters {
			// height: 25vh;
			width: 200px;
			// overflow: scroll;

			li {
				margin-left: 10px;
			}
		}

		.feautedproductList.notFeaturedProducts {
			// padding-top: 110px;
			padding-top: 90px;

			h1.pageTitle {
				font-size: 8rem;
				line-height: 6rem;
				opacity: 0.8;

				& > span {
					padding-left: 175px;

					&:before {
						width: 150px;
					}
				}
			}
		}
	}

	@media (max-width: 812px) {
		.feautedproductList {
			&.notFeaturedProducts {
				h1.pageTitle {
					font-size: 100px;
					line-height: 6rem;
					padding-left: 15px;
				}
				.filters {
					position: fixed;
					top: 0;
					bottom: 0;
					z-index: 3;
					background: red;
					display: none;
				}
				.productsContainer {
					padding: 0;
				}
			}

			.product {
				transform: none !important;
				margin-bottom: 90px !important;
				margin-top: 0 !important;

				.containerImage {
					width: 327px !important;
					height: 470px !important;

					.productImage {
						width: 327px !important;
						height: 470px !important;
					}
				}
			}
		}
	}

	@media (max-width: 1200px) {
		.row{
			justify-content: center;
		}
		.feautedproductList {
			
			.product {
				transform: none !important;
				margin-bottom: 90px !important;
				margin-top: 0 !important;

				.containerImage {
					width: 327px !important;
					height: 470px !important;

					.productImage {
						width: 327px !important;
						height: 470px !important;
						object-fit: cover;
					}
				}
			}
		}
	}

	@media (max-width: 1400px) {
		.feautedproductList {
			margin-bottom: 0;

			.product {
				margin-bottom: 130px;
				
				.productName {
					right: calc(50% - 70px);
					top: -30px !important;
					text-indent: 0;
					text-align: center;

					span {
						display: none;
					}
				}
			}
		}
	}

	@media (min-width: 10244px) {
		#bstoneproject.Home {
			.product:nth-of-type(3n + 2) {
				margin-top: 230px;
			}
		}
	}

	@media (min-width: 1400px) {
		.feautedproductList .product:nth-of-type(3n + 2) {
			margin-top: 130px;
		}
		
	}
	@media (min-width: 2200px) {		
		.titlehomefeatured{
			padding-top: 155px;
		}
	}


</style>
