var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"feautedproductList container",attrs:{"id":"feautedproductList "}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center titlehomefeatured"},[_vm._v(" "+_vm._s(_vm.home.featured_text1)+" "),_c('br'),_c('span',[_vm._v(_vm._s(_vm.home.featured_text2))])]),_c('div',{staticClass:"col-12 col-lg-11 productsContainer featuredCenter",attrs:{"itemscope":"","itemtype":"http://schema.org/ItemList"}},[_c('div',{staticClass:"row"},[_vm._l((_vm.products),function(featured_product,index){return [_c('router-link',{staticClass:"rellaxProductF product col-12 col-lg-6 col-xl-4 ",attrs:{"itemprop":"itemListElement","itemscope":"","itemtype":"http://schema.org/Product","to":{
									path:
									'/bloco-b/category/' +
									featured_product.category +
									'/collection/' +
									featured_product.collection +
									'/id/' +
									featured_product.id +
									'/name/' +
									featured_product.firstname +
									'-' +
									featured_product.secondname,
								},"data-rellax-speed":_vm.getDataSpeed(index)}},[_c('meta',{attrs:{"itemprop":"position","content":index}}),_c('a',{attrs:{"href":'/bloco-b/category/' +
											featured_product.category +
											'/collection/' +
											featured_product.collection +
											'/id/' +
											featured_product.id +
											'/name/' +
											featured_product.firstName +
											'-' +
											featured_product.secondName,"itemprop":"url"}}),_c('meta',{attrs:{"itemprop":"sku","content":featured_product.id}}),_c('div',{attrs:{"itemprop":"offers","itemtype":"https://schema.org/Offer","itemscope":""}},[_c('span',{attrs:{"itemprop":"priceCurrency","content":"EUR"}}),_c('link',{attrs:{"itemprop":"availability","href":"https://schema.org/InStock"}})]),_c('div',{staticClass:"containerImage"},[_c('img',{staticClass:"productImage",attrs:{"src":featured_product.imagem,"width":"300","height":"300","itemprop":"image"}})]),_c('p',{staticClass:"productName",attrs:{"itemprop":"name"}},[_c('mark',[_c('span'),_vm._v(" "+_vm._s(featured_product.firstname)+" "),_c('br'),_vm._v(" "+_vm._s(featured_product.secondname)+" ")])])])]})],2)])]),(_vm.hasPaging && _vm.hasLink)?_c('a',{staticStyle:{"display":"block","text-align":"center","padding":"80px 0 100px"},on:{"click":_vm.loadMoreClick}},[_c('seeMoreButton',[_vm._v(_vm._s(_vm.$t("actions_seemore")))])],1):_vm._e(),(!_vm.hasPaging && _vm.hasLink)?_c('router-link',{staticStyle:{"display":"block","text-align":"center","padding":"80px 0 100px"},attrs:{"to":"/"}},[_c('seeMoreButton',[_vm._v(_vm._s(_vm.$t("actions_loadmore")))])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }