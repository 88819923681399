<template>
	<keep-alive>
		<div class="modal-mask">
			<transition
				appear
				enter-active-class="animated slideInDown faster"
				leave-active-class="animated slideOutUp"
			>
				<div id="bg">
					<button class="closebutton" @click="$emit('close')">
						<closeIcon rectBackgroundColor="#FFFFFF" strokeColor="#3E3E3E" bordercolor="#ccc" />
					</button>
				</div>
				
				<!--button class="closeMenuButton" v-bind:aria-label="$t('button-arialabel-close-menu')" @click="showMobileMenu = false"></button-->
			</transition>

			<transition
				appear
				enter-active-class="animated slideInDown faster"
				leave-active-class="animated slideOutDown faster"
			>
				<nav class="menuMobile" id="menuMobile">
					<!-- Language /-->

					<ul itemscope itemtype="http://www.schema.org/SiteNavigationElement">
						<li v-for="(item, index) in itensMenu" :key="index" itemprop="name" >
							
							<router-link  v-if="!item.submenu" :to="item.link" itemprop="url" class="menu">{{item.name}}</router-link>
							
							<span v-else v-on:click="menushow" v-bind:class="{red: isActive}">
								{{ item.name }}
								
								<ul class="dropdown"
								:class="{isOpen}">
									<li
										v-for="child in item.submenu">
										<a class="dubmenu" :href="child.link">
											{{ child.name }}
										</a>
									</li>
								</ul>
							</span>
						</li>
					</ul>
				</nav>
			</transition>

			<transition
				appear
				enter-active-class="animated slideInUp faster"
				leave-active-class="animated slideOutUp faster"
			>
				<div class="mobileAdd">
					<!--a href="/simulador" class="simulator">{{ $t("simulator") }}</a-->

					<SocialButtons />
				</div>
			</transition>
		</div>
	</keep-alive>
</template>

<script>
	import closeIcon from "@/components/ui/closeIcon.vue";
	import Language from "@/components/subcomponents/Header_language.vue";
	import SocialButtons from "@/components/ui/socialButtons.vue";

	export default {
		name: "MenuMobile",
		components: {
			closeIcon,
			Language,
			SocialButtons
		},
		data() {
			return {
				itensMenu: [],
				showMobileMenu: true,
				isOpen: false,
				isActive: false
			};
		},
		mounted() {
			this.$http
				.get("https://www.bstone.pt/webservices/" + this.$i18n.locale + "/global2")
				.then(response => {
					this.itensMenu = response.data.menu;
					this.itemSubMenu = response.data.menu.submenu;
					
					// var element = document.querySelector('.menuContainer');
					// element.addEventListener("click", function(){ alert("Hello World!"); });
				});
		},
		methods: {
			menushow: function () {				
				// document.querySelector('body').classList.add("MyClass");
				const buttonGroup = document.getElementById("menuMobile");

				const buttonGroupPressed = (e) => {
					// console.log(e.target); // Get any clicked element inside button-group wrapper div~
					const boxes = document.querySelectorAll('.active');
					for (const box of boxes) {
						box.classList.remove('active');
					}
					e.target.classList.add('active');
				}

				buttonGroup.addEventListener('click', buttonGroupPressed);
			},
			toggleSubItemVisibility({ target }) {
				const item = target.closest('.menuContainer span');
				// we check because the user might have clicked on the surrounding ul
				if (item) {
					item.classList.toggle('active');
				}
			}
  		}
	};
	
	
</script>

<style lang="scss">
	#bg {
		width: 100%;
		height: 120px;
		position: fixed;
		background: #fff;
		z-index: 3;
	}
	
	.modal-mask {
		position: fixed;
		z-index: 5;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		// overflow-y: hidden;
		overflow-y: scroll;
	}

	.menuMobile {

		background: #fff;
		padding-top: 150px;
		padding-bottom: 150px;
		//height: 100%;
		

		& > ul {
			list-style: none;
			width: 100vw;
			padding-left: 0;

			& li {
				width: 100%;
				text-align: center;
				
    			list-style-type: none;


				span,a {
					display: block;
					font-family: "Oswald", sans-serif;
					font-size: 1.4rem;
					line-height: 4rem;
					font-weight: 300;
					margin-left: -10px;
				}
				span{
					
				}
				.dropdown a{
					font-weight: 500;
				}
			}
		}
	}
	.menuMobile .dropdown {
		padding-left: 0;
	}
	.mobileAdd {
		background-color: #313131;
		position: fixed;
		text-align: center;
		z-index: 2;
		top: 550px;
		bottom: 0;
		left: 0;
		right: 0;
		overflow: hidden;

		.social-icons {
			justify-content: center;
		}
	}

	.openMenuButton {
		display: block;
		position: fixed;
		width: 90px;
		height: 90px;
		bottom: 10%;
		right: 15px;
		border: 0;
		background-size: 30px;
		background: center center no-repeat url(../../assets/images/icons/menu.svg);
	}

	
</style>
