<template>
    <div class="footerMenu col-12 col-lg-8 order-2 order-lg-1 d-flex justify-content-between">
        

        

        <nav class="col-12 col-md-3 mobileHided">
            <!-- <router-link    v-for="item in itemsMenu1" 
                            :to="item.link" 
                            :key="item.id" 
                            itemprop="url">{{item.name}}</router-link> -->
                            <!-- <router-link    v-for="item in itemsMenu5" 
                            to="/" 
                            :key="item.id" 
                            itemprop="url">{{item.name}}</router-link> -->
                    <template v-for="item in itemsMenu5">
                        <div class="titleFooter">{{ item.name }}</div>
                        
                       
                            <template
                                v-for="child in item.submenu">
                                <a :href="child.link">
                                    {{ child.name }}
                                </a>
                            </template>
                        
                    </template>
        </nav>
        
        <nav class="col-12 col-md-3 mobileHided">
            <!-- <router-link    v-for="item in itemsMenu2" 
                            :to="item.link" 
                            :key="item.id" 
                            itemprop="url">{{item.name}}</router-link> -->
                    <template v-for="item in itemsMenu6">
                        <div class="titleFooter">{{ item.name }}</div>
                        
                       
                            <template
                                v-for="child in item.submenu">
                                <a :href="child.link">
                                    {{ child.name }}
                                </a>
                            </template>
                        
                    </template>
        </nav>

        <nav class="col-12 col-md-3 mobileHided">
            <div class="titleFooter">B stone</div>
            <router-link    v-for="item in itemsMenu7" 
                            :to="item.link" 
                            :key="item.id" 
                            itemprop="url">{{item.name}}</router-link>
                    <!-- <template v-for="item in itemsMenu7">
                        <div class="titleFooter">{{ item.name }}</div>
                        
                       
                            <template
                                v-for="child in item.submenu">
                                <a :href="child.link">
                                    {{ child.name }}
                                </a>
                            </template>
                        
                    </template> -->
        </nav>
    
        <nav class="col-12 col-md-3 mobileHided secondaryLinks">
            <div class="titleFooter" style="opacity:0">menu4</div>
            <router-link    v-for="item in itemsMenu3" 
                            :to="item.link" 
                            :key="item.id" 
                            itemprop="url">{{item.name}}</router-link>
        </nav>
    </div>
</template>

<script>
export default {
    data() {
        return {
            itemsMenu1: [],
            itemsMenu2: [],
            itemsMenu3: []
        }
    },
    created() {
        this.$eventBus.$on('jsonGlobalLoaded', (response) => {
            this.itemsMenu1 = response.data.menu
            this.itemsMenu2 = response.data.footer.menuFooter
            this.itemsMenu3 = response.data.footer.menuFooter2
            this.itemsMenu5 = response.data.footer.menuFooter5
            this.itemsMenu6 = response.data.footer.menuFooter6
            this.itemsMenu7 = response.data.footer.menuFooter7
        })   
    },
    beforeDestroy() {
        this.$eventBus.$off('jsonGlobalLoaded') // releases the subscription
    }
}
</script>
<style>
    .titleFooter{
        font-family: 'Oswald';
        font-size: 13px;
        color: #A7A7A7;
        text-transform: lowercase;
        padding-bottom: 20px;
    }
</style>
