var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$route.name,attrs:{"id":"bstoneproject"}},[_c('transition',{attrs:{"leave-active-class":"animated slideOutUp faster delay-1s"}},[(_vm.isLoaded === false)?_c('Preloader',{staticClass:"loader"}):_vm._e()],1),_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInDown delay-2s","leave-active-class":"animated slideOutUp"}},[(!(['faqs', 'privacy-policy'].indexOf(_vm.$route.name) > -1))?_c('Header',[_c('div',{staticClass:"logo d-flex col-lg-2 col-md-2",attrs:{"itemscope":"","itemtype":"http://schema.org/Organization","legalName":"BStone"}},[_c('router-link',{attrs:{"to":"/","itemprop":"url","alt":'Link to ' + _vm.$t('logo-aria-label')}},[(!_vm.isMobile())?_c('img',{staticClass:"d-none d-md-block",attrs:{"itemprop":"logo","src":require("./assets/images/logo/logo_home.svg"),"height":"79px","width":"57px","alt":_vm.$t('logo-aria-label'),"title":_vm.$t('logo-aria-label'),"aria-label":_vm.$t('logo-aria-label'),"importance":"high"}}):_vm._e(),_c('img',{staticClass:"d-block d-md-none",attrs:{"itemprop":"logo","height":"29px","width":"97px","src":require("./assets/images/logo/logo_home_mobile.svg"),"alt":_vm.$t('logo-aria-label'),"title":_vm.$t('logo-aria-label'),"aria-label":_vm.$t('logo-aria-label'),"importance":"high"}})])],1),(!_vm.isMobile())?_c('Menu'):(_vm.isMobile() && _vm.showMobileMenu == true)?_c('MenuMobile',{on:{"close":function($event){_vm.showMobileMenu = false}}}):_vm._e(),(_vm.isMobile())?_c('button',{staticClass:"openMenuButton",attrs:{"aria-label":_vm.$t('button-arialabel-open-menu')},on:{"click":function($event){_vm.showMobileMenu = true}}}):_vm._e()],1):_vm._e()],1),_c('router-view'),(!_vm.isMobile() && ['Home', 'explore', 'project', 'innovation', 'listpage-categories', 'listpage-collection'].indexOf(_vm.$route.name) > -1)?_c('Scroll'):_vm._e(),_c('keep-alive',[(
          !(
            [
              'faqs',
              'privacy-policy',
              'news',
              'newsdetail',
			  'newsdetail2',
              'productDetail',
			  'productDetail2',
			  'productDetail3',
              'variantDetail',
			  'variantDetail2',
              'shoppingcart',
			  'company',
			  'productDetailType',
			  'press' ].indexOf(_vm.$route.name) > -1
          )
        )?_c('Address'):_vm._e()],1),_c('keep-alive',[(
          !(
            [
              'faqs',
              'privacy-policy',
              'news',
              'newsdetail',
			  'newsdetail2',
              'productDetail',
			  'productDetail2',
			  'productDetail3',
              'variantDetail',
			  'variantDetail2',
              'shoppingcart',
			  'company',
			  'productDetailType',
			  'press' ].indexOf(_vm.$route.name) > -1
          )
        )?_c('Contacts'):_vm._e()],1),_c('keep-alive',[(
          !(
            [
              'faqs',
              'privacy-policy',
              'productDetail',
			  'productDetail2',
              'variantDetail',
			  'variantDetail2',
              'shoppingcart',
			  'productDetailType' ].indexOf(_vm.$route.name) > -1
          )
        )?_c('Footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }