<template>
    <!-- <div class="box" v-scroll="handleScroll">
        <div id="simulatorLink" rel="nofollow" href="/simulator" alt="Simulator Link">
        
            <svg class="simutadorButton" xmlns="http://www.w3.org/2000/svg" width="128.713" height="128.241" viewBox="0 0 128.713 128.241" aria-labelledby="svgsimulatortitle" role="img">
                <title id="svgsimulatortitle">Enter on Simulator Page</title>
                <rect width="52.764" height="52.764" transform="translate(90.738 37.83) rotate(90)" fill="transparent" stroke="#161615" stroke-miterlimit="10" stroke-width="2"/>
                
                <g transform="translate(0 59.581)">
                    <g id="Grupo_582" data-name="Grupo 582">
                    <rect width="6.55" height="6.55" transform="translate(0 4.631) rotate(-45)" fill="#b76646"/>
                    <rect width="6.55" height="6.55" transform="translate(119.45 4.631) rotate(-45)" fill="#b76646"/>
                    </g>
                </g>
                <g transform="translate(6.463)">
                    <path d="M361.415,587.233l-.831-1.864a3.5,3.5,0,0,0,1.645-1.443c.307-.62.234-.983-.112-1.153-.37-.183-.684.152-1.27.678l-.882.766a2.554,2.554,0,0,1-2.93.5c-1.324-.654-1.822-2.382-.986-4.076a4.155,4.155,0,0,1,2.309-2.066l.783,1.662a2.847,2.847,0,0,0-1.326,1.274c-.248.5-.222.9.125,1.072.37.181.719-.2,1.341-.763l.834-.76a2.375,2.375,0,0,1,2.872-.468c1.312.647,1.94,2.321.986,4.254A5,5,0,0,1,361.415,587.233Z" transform="translate(-355.638 -545.155)" fill="#b76646"/>
                    <path d="M365.679,566.287l1.481-1.743,6.589,5.6-1.481,1.743Z" transform="translate(-357.33 -542.79)" fill="#b76646"/>
                    <path d="M377.81,553.613l2.021-1.379,3.07,2.19c.4.305.811.67,1.227,1l.044-.03c-.153-.507-.343-1.023-.481-1.508l-.96-3.627,2.023-1.378,4.87,7.143-1.715,1.168-1.438-2.109a29.859,29.859,0,0,1-1.5-2.715l-.044.03.575,2.119.732,2.962-1.011.689-2.489-1.763L381,555.078l-.044.03a30.123,30.123,0,0,1,1.98,2.385l1.438,2.11-1.693,1.153Z" transform="translate(-359.375 -540.15)" fill="#b76646"/>
                    <path d="M400.716,546.6l-1.26-4.286,2.194-.645,1.334,4.541c.436,1.481.976,1.9,1.843,1.649s1.118-.911.683-2.391l-1.334-4.541,2.118-.622,1.26,4.286c.832,2.831.15,4.473-2.171,5.156S401.549,549.43,400.716,546.6Z" transform="translate(-363.024 -538.704)" fill="#b76646"/>
                    <path d="M421.975,538.389l2.286-.009.027,6.727,3.269-.014.008,1.915-5.556.022Z" transform="translate(-366.82 -538.38)" fill="#b76646"/>
                    <path d="M443.072,540.637l2.67.725.274,9.035-2.336-.635.071-1.882-2.336-.636-.892,1.66-2.259-.613Zm-.8,5.008,1.54.418.016-.616c.012-.852.082-1.921.1-2.812l-.052-.014c-.41.8-.887,1.744-1.31,2.484Z" transform="translate(-369.565 -538.76)" fill="#b76646"/>
                    <path d="M460.931,549.429l-1.982-1.145.959-1.659,5.943,3.435-.958,1.66-1.981-1.146-3.368,5.829-1.982-1.146Z" transform="translate(-372.819 -539.77)" fill="#b76646"/>
                    <path d="M473.586,561.835c2.051-1.973,4.325-1.911,5.986-.186s1.625,4.008-.415,5.974-4.383,1.966-6.042.242S471.536,563.809,473.586,561.835Zm3.948,4.1c1.13-1.088,1.339-2.175.619-2.922s-1.813-.58-2.944.508-1.4,2.232-.677,2.978S476.4,567.024,477.534,565.936Z" transform="translate(-375.244 -542.084)" fill="#b76646"/>
                    <path d="M490.88,577.16l1.481,2.919c.842,1.662,1,3.375-.928,4.35a2.655,2.655,0,0,1-2.863-.114l-2.142,3.19-1.156-2.279,1.913-2.641-.4-.785-2.587,1.313-1.035-2.041Zm-2.105,4.56c.409.806.983,1.053,1.647.716s.661-.873.252-1.68l-.373-.735-1.9.963Z" transform="translate(-377.135 -544.917)" fill="#b76646"/>
                </g>
                <g transform="translate(6.615 85.653)">
                    <path d="M487.561,642.013l.831,1.863a3.509,3.509,0,0,0-1.645,1.443c-.307.62-.234.983.113,1.153.369.182.683-.153,1.27-.678l.883-.767a2.554,2.554,0,0,1,2.929-.5c1.324.654,1.823,2.382.987,4.077a4.161,4.161,0,0,1-2.31,2.065l-.783-1.661a2.846,2.846,0,0,0,1.327-1.275c.247-.5.221-.9-.125-1.071-.371-.182-.718.2-1.341.762l-.836.761a2.376,2.376,0,0,1-2.871.468c-1.311-.647-1.939-2.322-.985-4.254A5,5,0,0,1,487.561,642.013Z" transform="translate(-377.52 -641.502)" fill="#b76646"/>
                    <path d="M483.444,663.224l-1.481,1.744-6.59-5.6,1.482-1.744Z" transform="translate(-375.974 -644.134)" fill="#b76646"/>
                    <path d="M470.554,674.98l-2.021,1.377-3.071-2.189c-.4-.305-.81-.669-1.226-1l-.044.029c.152.508.342,1.023.48,1.508l.959,3.627-2.021,1.378-4.869-7.144,1.714-1.168,1.438,2.11a29.731,29.731,0,0,1,1.5,2.715l.044-.03-.576-2.119-.731-2.961,1.011-.689,2.49,1.763,1.739,1.324.044-.03a30.249,30.249,0,0,1-1.98-2.385l-1.438-2.11,1.694-1.153Z" transform="translate(-373.17 -645.854)" fill="#b76646"/>
                    <path d="M448.3,682.456l1.256,4.289-2.195.643-1.332-4.545c-.434-1.482-.974-1.9-1.842-1.65s-1.12.91-.686,2.391l1.332,4.544-2.118.621-1.258-4.288c-.831-2.834-.146-4.476,2.177-5.157S447.474,679.623,448.3,682.456Z" transform="translate(-370.2 -647.753)" fill="#b76646"/>
                    <path d="M427.651,690.857l-2.286.01-.027-6.728-3.271.015-.008-1.915,5.558-.022Z" transform="translate(-366.987 -648.279)" fill="#b76646"/>
                    <path d="M406.116,688.385l-2.67-.725-.274-9.035,2.336.635-.071,1.882,2.336.636.893-1.66,2.259.614Zm.8-5.008-1.541-.419-.016.616c-.011.852-.082,1.921-.1,2.812l.051.014c.41-.8.888-1.744,1.31-2.484Z" transform="translate(-363.803 -647.673)" fill="#b76646"/>
                    <path d="M388.148,679.59l1.981,1.145-.959,1.658-5.943-3.435.958-1.659,1.982,1.146,3.366-5.83,1.982,1.146Z" transform="translate(-360.441 -646.66)" fill="#b76646"/>
                    <path d="M375.388,667.386c-2.05,1.973-4.325,1.911-5.986.187s-1.625-4.009.416-5.975,4.382-1.966,6.042-.242S377.438,665.413,375.388,667.386Zm-3.947-4.1c-1.131,1.087-1.34,2.174-.619,2.922s1.813.58,2.943-.509,1.4-2.231.677-2.977S372.571,662.2,371.441,663.286Z" transform="translate(-357.911 -644.549)" fill="#b76646"/>
                    <path d="M357.9,651.744l-1.481-2.92c-.843-1.661-1-3.374.928-4.349a2.653,2.653,0,0,1,2.863.115l2.142-3.191,1.156,2.279-1.913,2.641.4.783,2.588-1.312,1.035,2.041ZM360,647.185c-.41-.807-.983-1.054-1.647-.717s-.661.874-.252,1.681l.373.735,1.9-.964Z" transform="translate(-355.821 -641.398)" fill="#b76646"/>
                </g>
            </svg>
          
        </div>
    </div> -->
</template>

<script>
export default {
    methods: {
        handleScroll: function (evt, el) {

            var scrollPosition = window.scrollY;
            var rotation = scrollPosition/10 ;

            el.setAttribute(
                'style',
                'transform: rotate(' + rotation + 'deg)'
            )
        }
    }
}
</script>

<style lang="scss">
.box {
    position: fixed;
    bottom: 45vh;
    right: 5%;
    width: 130px;
    height: 130px;
    z-index: 1;
    transform-origin: center;
    display: block;

    div {
        display:block;
        -webkit-transition:     all 0.2s ease;
        -moz-transition:        all 0.2s ease;
        -o-transition:          all 0.2s ease;
        transition:             all 0.2s ease;
        
        .simutadorButton {
            animation-name: rotate;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in;
            animation-duration: 21s;
            transform-origin: center;

            #rectangle{
                -webkit-transition:     all 0.2s ease;
                -moz-transition:        all 0.2s ease;
                -o-transition:          all 0.2s ease;
                transition:             all 0.2s ease;
            }
        }
    }
    
    /* &:hover{
        -webkit-animation-play-state:   paused;
        -moz-animation-play-state:      paused;
        -o-animation-play-state:        paused;
        animation-play-state:           paused;

        a{transform: scale(.85);}
        .simutadorButton{
            -webkit-animation-play-state:   paused;
            -moz-animation-play-state:      paused;
            -o-animation-play-state:        paused;
            animation-play-state:           paused;
        }
        *{ fill: #161615; }
    } */
}


@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
</style>

